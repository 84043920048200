import React from 'react';
import { Form, Review } from '.';

export interface FormOrReviewProps {
  isReviewing: boolean;
  isSubmitting: boolean;
  onGoBack: () => void;
  onClose: () => void;
}

export default function FormOrReview(props: FormOrReviewProps) {
  if (props.isReviewing) {
    return <Review {...props} />;
  }

  return <Form {...props} />;
}
