import { compile } from 'path-to-regexp';
import store from 'store2';

import { STORAGE_KEYS } from 'core/config';

export const injectSlugsToPath = (path: string, slugs: {}) => {
  const toPath = compile(path, { encode: encodeURIComponent });
  return toPath(slugs);
};

/**
 * @name setMostRecentlyViewedBrand
 * @author Bruce MacFarlane
 * @description
 * For users with access to many brands (mostly internal users), we want to
 * store the most recent brand they accessed, since it makes most sense to send
 * them back there instead of some default brand on app load if no brand is set.
 */
export const setMostRecentlyViewedBrand = (brandId: string) => {
  if (!brandId) return;
  const currentlySetBrand = store.get(STORAGE_KEYS.mostRecentlyViewedBrand);
  if (currentlySetBrand !== brandId) {
    store.set(STORAGE_KEYS.mostRecentlyViewedBrand, brandId);
  }
};
