/* eslint-disable no-nested-ternary */
/**
 * @name StoreScheduleCard
 * @author Magnus <magnus@jyve.com>
 * @description
 * A Card to display information on a job schedule for a specific store
 */

import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

import { useCurrentBrand } from 'helpers/hooks';
import { StoreLocationAddress } from 'types/api';
import { EstimatedDurationChip, GreyChip } from 'components/chips';

import { Card, CardTitle, ErrorBanner } from './styles';

export interface StoreScheduleProps {
  type: string | null;
  banner_name?: string;
  store_location_self_identity?: string | null;
  time_window: string | null;
  estimated_cost: string | null;
  estimated_time?: number;
  store_location_address?: StoreLocationAddress;
  recurrenceDay: string | null;
  error: string | null;
  dryRunErrored: boolean;
}

export default function StoreScheduleCard({
  type,
  banner_name,
  store_location_self_identity,
  time_window,
  estimated_cost,
  estimated_time,
  store_location_address,
  recurrenceDay,
  error,
  dryRunErrored,
}: StoreScheduleProps) {
  const currentBrand = useCurrentBrand();

  return (
    <Card errored={!!error}>
      <Collapse in={!!error}>
        <ErrorBanner>
          <Typography variant="body1" gutterBottom>
            <ErrorTwoToneIcon />
            <strong>Ruh-roh!</strong>
          </Typography>
          <Typography variant="body1">{error}</Typography>
        </ErrorBanner>
      </Collapse>

      <CardContent>
        <CardTitle>
          <Typography variant="body1">
            <strong>{type || 'Jyve'}</strong> for{' '}
            <strong>{currentBrand.name}</strong>
          </Typography>
        </CardTitle>

        <Collapse in={!error && !dryRunErrored}>
          <Typography variant="body1" color="textSecondary">
            {time_window ? (
              <>{time_window}</>
            ) : (
              <Skeleton variant="rect" width={120} component="span" />
            )}
          </Typography>

          {recurrenceDay ? (
            <Typography variant="body1" color="textSecondary">
              Repeats every {recurrenceDay}
            </Typography>
          ) : null}

          <Divider />

          <Typography variant="caption" component="div">
            <strong>
              {banner_name} ({store_location_self_identity})
            </strong>
          </Typography>

          <Typography color="textSecondary" variant="caption">
            {store_location_address?.address}
          </Typography>

          <section className="chips">
            {estimated_time ? (
              <EstimatedDurationChip estimatedTime={estimated_time} />
            ) : (
              <Skeleton variant="rect" width={60} />
            )}

            {estimated_cost ? (
              <GreyChip label={estimated_cost} color="default" size="small" />
            ) : (
              <Skeleton variant="rect" width={60} />
            )}
          </section>
        </Collapse>
      </CardContent>
    </Card>
  );
}
