import moment, { Moment } from 'moment';

import { DayOfWeekShape } from 'react-dates';
import { DateRange } from 'types';
import { REPORT_DATE_FORMAT, REPORT_DATE_REGEX } from 'core/config';

export * from './tz-aware';

export function weekStartsOnToDayOfWeek(
  weekStartsOn: string | null
): DayOfWeekShape {
  const DAY_DICT: { [k: string]: number } = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  const dayOfWeek = DAY_DICT[weekStartsOn || 'sunday'];
  return dayOfWeek as DayOfWeekShape;
}

// Max historical date represents the date when JPP initially got released
export const maxHistoricalDate = moment('2019-12-01');
export const dateFormatter = (
  startDate: string,
  endDate: string,
  isSummary?: boolean
) => {
  const s1 = moment(startDate, ['MM-DD-YYYY', 'YYYY-MM-DD']);
  const s2 = moment(endDate, ['MM-DD-YYYY', 'YYYY-MM-DD']);
  const diffNumberOfDays = s2.diff(s1) / (3600 * 24 * 1000);

  let formattedDate = '';
  if (s1.format('MMM') === s2.format('MMM') && diffNumberOfDays > 1)
    formattedDate = `${s1.format('DD')} ${s1.format('MMM')}. - ${s2.format(
      'DD'
    )} ${s1.format('MMM')}.`;
  else if (
    s1.format('MMM') === s2.format('MMM') &&
    diffNumberOfDays === 1 &&
    isSummary
  )
    formattedDate = `${s1.format('DD')} ${s1.format('MMM')}. - ${s2.format(
      'DD'
    )} ${s1.format('MMM')}.`;
  else if (diffNumberOfDays > 1)
    formattedDate = `${s1.format('DD')} ${s1.format('MMM')}. - ${s2.format(
      'DD'
    )} ${s2.format('MMM')}.`;
  else formattedDate = s1.format('MMM D');

  const yesterday = moment()
    .subtract('1', 'days')
    .format('M-DD-YYYY');

  if (formattedDate === yesterday) formattedDate = 'Yesterday';

  return formattedDate;
};

export const getFilterDefaultDate = (days = 1): Moment => {
  return moment().subtract(days, 'days');
};

/**
 * getDefaultDateRange
 * Based on a company's defined start of week ('Sunday', or 'Monday', etc.)
 * we want to load the previous full week as the date range starting on that day.
 * For example: A company's start of the week is 'monday' and today is
 * a Tuesday.  To emcompass a full available week we need to back up to the
 * previous Monday, so we can encompas Monday through Sunday (2 days back from
 * today/Tuesday) as an available date range.
 */
export const getDefaultDateRange = (weekStartsOn: string | null): DateRange => {
  const dayOfWeek = weekStartsOnToDayOfWeek(weekStartsOn || null);
  const start = moment()
    .hours(0)
    .subtract(dayOfWeek, 'day')
    .startOf('week')
    .subtract(1, 'week')
    .add(dayOfWeek, 'day');
  const end = moment(start).add(6, 'days');
  return { startDate: start, endDate: end };
};

export const getMomentFromOptionalDateString = (
  dateStr: string | null
): Moment | null => {
  if (dateStr && REPORT_DATE_REGEX.test(dateStr)) {
    return moment(dateStr, REPORT_DATE_FORMAT);
  }
  return null;
};
