// eslint-ignore @typescript-eslint/ban-ts-ignore
import { createMuiTheme } from '@material-ui/core';
import { Shadows } from '@material-ui/core/styles/shadows';

import { hexToRGB } from 'helpers';

const { breakpoints, spacing } = createMuiTheme({});
const mobile = breakpoints.down('sm');

export const colors = {
  aqua: '#229592',
  avocado: '#1D7874',
  avocadoBright: '#1D8E89',
  avocado50: '#8EBBB9',
  avocado20: '#E1F2F3',
  black: '#3E3E3E',
  porcelain: '#EEF1F2',
  cloud: '#F3F6F9',
  daintree: '#012429',
  firefly: '#0E2F33',
  ghost: '#CAD1D8',
  grey: '#757E87',
  infrared: '#E54360',
  infraredBright: '#F14262',
  infrared800: '#A93454',
  ink: '#02363D',
  lightgrey: '#f4f4f4',
  pomegranate: '#F44336',
  viridian: '#3BD4AE',
  white: '#FFFFFF',
};

export const primaryGradient =
  'linear-gradient(90deg, #1D7874 0%, #3BD4AE 100%)';

export const tableColors = {
  firstChild: colors.lightgrey,
  totalRowFirstChild: '#e8e8e8',
  columnFirstChild: '#ffffff',
  anomalies: colors.infrared,
  subRowHover: '#dbdbdb',
  shadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
};

export const jobStatusColors: Record<string, string> = {
  total: colors.white,
  completed: colors.ink,
  in_progress: colors.ink,
  active: colors.grey,
  claimed: colors.avocado,
  unclaimed: colors.avocado50,
  expired:
    'repeating-linear-gradient(45deg, #67868B 0px, #67868B 10px, #7A959A 10px, #7A959A 12px)',
  canceled: colors.pomegranate,
};

const shadows = {
  elevation8: '0px 2px 8px rgba(176, 190, 197, 0.32)',
  elevation16: '0px 4px 16px rgba(176, 190, 197, 0.32)',
  elevation24: '0px 8px 24px rgba(176, 190, 197, 0.32)',
};

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    type: 'light',
    common: { black: colors.black, white: colors.white },
    action: {
      selected: hexToRGB(colors.avocado, 0.12),
      focus: `${colors.viridian}1a`,
      hover: `${colors.viridian}1a`,
    },
    background: {
      paper: '#ffffff',
      default: colors.cloud,
    },
    primary: {
      main: colors.avocado,
    },
    secondary: { main: colors.infrared },
    error: { main: colors.pomegranate },
    text: {
      primary: colors.black,
      secondary: colors.grey,
    },
    divider: colors.cloud,
  },
  // We don't want all 25 elevations because ... well ... it's about 22 too many
  // So here we fill in the same shadows for 1-8, 9-16 and 17-24
  shadows: [
    'none',
    ...Array(8).fill(shadows.elevation8),
    ...Array(8).fill(shadows.elevation16),
    ...Array(8).fill(shadows.elevation24),
  ] as Shadows,
  typography: {
    fontFamily: "'IBM Plex Sans', sans-serif",
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
      [mobile]: {
        fontSize: '3.125rem',
      },
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      [mobile]: {
        fontSize: '2.625rem',
      },
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.5,
      [mobile]: {
        fontSize: '2.125rem',
      },
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 400,
      [mobile]: {
        fontSize: '1.5rem',
        fontWeight: 400,
      },
    },
    // NOTE: Don't overwrite h6, it's used in Dialogs
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 400,
      [mobile]: {
        fontSize: '1.625rem',
      },
    },
    subtitle2: {
      fontSize: '1.375rem',
      fontWeight: 600,
      [mobile]: {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      [mobile]: {
        fontSize: '1.125rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      [mobile]: {
        fontSize: '1rem',
      },
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      [mobile]: {
        fontSize: '0.875rem',
      },
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 600,
      letterSpacing: '0.06em',
      textTransform: 'uppercase',
      [mobile]: {
        fontSize: '1rem',
      },
    },
  },
  shape: {
    borderRadius: 3,
  },

  // OVERRIDES - https://v4-3-3.material-ui.com/customization/globals/#css
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white',
        color: colors.black,
      },
    },
    MuiContainer: {
      root: {
        [breakpoints.up('xs')]: {
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        },
        [breakpoints.up('md')]: {
          paddingLeft: spacing(4),
          paddingRight: spacing(4),
        },
      },
      maxWidthSm: {
        maxWidth: `${400 + spacing(4)}px !important`,
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: 58,
        [breakpoints.up('sm')]: {
          minHeight: 72,
        },
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: 0.5,
        },
      },
      contained: {
        backgroundColor: colors.white,
        '&:hover': {
          backgroundColor: colors.white,
          boxShadow: shadows.elevation24,
        },
        '&$disabled': {
          color: colors.black,
          backgroundColor: colors.white,
          border: `1px solid ${colors.porcelain}`,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.avocadoBright,
        },
        '&$disabled': {
          color: colors.white,
          backgroundColor: colors.avocado,
          border: 'none',
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.infraredBright,
        },
        '&$disabled': {
          color: colors.white,
          backgroundColor: colors.infrared,
          border: 'none',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(2px)',
        backgroundColor: 'rgba(2, 54, 61, 0.6)',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingRight: 12,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: colors.porcelain,
      },
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: hexToRGB(colors.avocado, 0.8),
        },
        '&$focused $notchedOutline': {
          borderColor: hexToRGB(colors.avocado, 0.8),
        },
        '&$disabled $notchedOutline': {
          borderColor: hexToRGB(colors.avocado, 0.38),
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: colors.grey,
        lineHeight: '150%',
        backgroundColor: colors.cloud,
        padding: spacing(2),
        boxShadow: shadows.elevation8,
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
        '&:hover': {
          background: 'transparent !important',
          boxShadow: shadows.elevation16,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 400,
        fontSize: '1rem',
        borderBottomColor: colors.cloud,
      },
      head: {
        fontWeight: 600,
        fontSize: '0.75rem',
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: `${colors.avocado} !important`,
      },
    },
    MuiTablePagination: {
      caption: {
        fontWeight: 400,
      },
      select: {
        padding: 8,
        paddingRight: 0,
      },
    },
    MuiChip: {
      root: {
        color: colors.avocado,
        backgroundColor: colors.avocado20,
        fontSize: '0.875rem',
      },
      icon: {
        color: colors.avocado,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'initial',
        },
      },
      icon: {
        color: colors.avocado,
      },
    },
    MuiMenuItem: {
      root: {
        color: colors.ink,
        '&:focus, &:hover': {
          backgroundColor: 'rgba(59, 212, 174, 0.2)',
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 3,
      },
    },
    MuiCardHeader: {
      root: {
        padding: spacing(2, 2, 1, 2),
      },
    },
    MuiTypography: {
      root: {
        '& strong': {
          fontWeight: 600,
        },
      },
    },
    MuiBreadcrumbs: {
      li: {
        lineHeight: 1,
      },
    },
    MuiListItemText: {
      multiline: {
        marginTop: `${spacing(1)}px`,
        marginBottom: `${spacing(1)}px`,
      },
    },
    // TODO: Remove the ts-ignore when material-ui has TypeScript support for lab components
    // eslint-disable-next-line
    // @ts-ignore
    MuiTabPanel: {
      root: {
        padding: 16,
      },
    },
    MuiPaginationItem: {
      root: {
        fontWeight: 'normal',
      },
    },
    MuiAlert: {
      message: {
        textAlign: 'left !important',
      },
    },
  },

  props: {
    MuiButton: {
      size: 'large',
    },
    MuiContainer: {
      maxWidth: 'lg', // sets the max-width of containers to 1440px by default
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiListItemText: {
      primaryTypographyProps: {
        variant: 'caption',
        color: 'textSecondary',
      },
      secondaryTypographyProps: {
        variant: 'body1',
        color: 'textPrimary',
      },
    },
  },
});

export default theme;
