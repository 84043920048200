import EmptyState from 'components/EmptyState';
import HeaderAndDrawer from 'components/HeaderAndDrawer';
import PermissionDenied from 'components/PermissionDenied';
import ZendeskHelpLauncher from 'components/ZendeskLauncher';
import { VIEWS } from 'core/config';
import { ContentContainer, MainWrapper } from 'core/styles';
import { useCurrentUser, useHeaderTitle } from 'helpers/hooks';
import { setMostRecentlyViewedBrand } from 'helpers/routing';
import {
  doesUserHaveAccess,
  getFiltersForInsights,
} from 'helpers/userAccessAndFiltersUtils';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';
import AuthService from 'services/auth';
import { AppState } from 'state/store';
import { InsightsFilterStructureForClient } from 'types';

import { useRollbar } from '@jyveapp/react-component-library';

import { SentryMonitoredRoute } from '../sentry';

const SELF_SERVE_PATH_REGEX = new RegExp('/brands/.*/jobs');

/**
 * PrivateRoute
 *
 * @desciption
 * 1: Checks if the user is logged in, if not it redirects to the login
 * screen.
 *
 * 2: If store.user.hasOutdatedAccessInfo, this redirects to a component which
 * will load most current user access info from API, then redirect to original
 * URL.
 *
 * 3: Checks for user access to brand across all selected territories.  If
 * access is denied, returns PermissionDenied component.
 *
 * 4: Uses route info to create a 'filters' object that it passes to Component.
 */

interface ComponentProps extends RouteComponentProps<any> {
  filters: InsightsFilterStructureForClient;
}

interface PrivateRouteProps extends RouteProps {
  pageTitle?: string;
  backButtonPath?: string;
  hideAppBarOnScroll?: boolean;
  component: React.ComponentType<ComponentProps> | React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const [subDomain] = window.location.host.split('.');
  const { setHeaderTitle } = useHeaderTitle();
  const hasOutdatedAccessInfo = useSelector(
    (state: AppState) => state.user.hasOutdatedAccessInfo
  );
  const userAccessibleBrands = useSelector(
    (state: AppState) => state.user.brands
  );
  const currentUser = useCurrentUser();
  const userAccessibleTerritories = useSelector(
    (state: AppState) => state.user.territories
  );
  const rollbar = useRollbar();

  const userHasNoBrandsOrTerritories =
    (userAccessibleBrands && userAccessibleBrands.length === 0) ||
    (userAccessibleTerritories &&
      Object.keys(userAccessibleTerritories).length === 0);

  useEffect(() => {
    if (currentUser) {
      rollbar.configure({
        payload: {
          person: {
            id: currentUser.id,
            username: currentUser.name,
            email: currentUser.email,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const { backButtonPath, pageTitle, hideAppBarOnScroll } = rest;

  // If the current route is given a page title, we set it here
  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle(pageTitle || null);
    }
  }, [pageTitle, setHeaderTitle]);

  return (
    <SentryMonitoredRoute
      {...rest}
      render={routeProps => {
        if (AuthService.isAuthenticated()) {
          if (hasOutdatedAccessInfo) {
            return (
              <Redirect
                to={{
                  pathname: VIEWS.preload.path,
                  state: { from: routeProps.location },
                }}
              />
            );
          }

          /* BEGIN: does user have access handler */
          // TODO: Move access control into CASL
          const {
            match: { params, url },
            location: { search },
          } = routeProps;
          const brandId = params?.brandId || null;

          let userHasAccess = false;
          try {
            userHasAccess = doesUserHaveAccess(
              brandId,
              search,
              userAccessibleBrands,
              userAccessibleTerritories
            );
          } catch (e) {
            rollbar.warning(e);
          }

          const brand = userAccessibleBrands
            ? userAccessibleBrands.filter(b => b.public_id === brandId).pop() ||
              null
            : null;
          const filters = getFiltersForInsights(brand, search);

          // TODO: Move to using react-router's match functionality
          const isSelfServeRoute = SELF_SERVE_PATH_REGEX.test(url);
          if (isSelfServeRoute) {
            userHasAccess = !!brand?.self_serve;
          }
          /* END: does user have access handler */

          // TODO: Remove this when walmart.jyve.com is gone
          if (subDomain === 'walmart') {
            userHasAccess = true;
          }

          if (userHasAccess && brandId) {
            /**
             * This is to help with getting the user back to the most recently
             * viewed brand on page load, when they have access to many.
             */
            setMostRecentlyViewedBrand(brandId);
          }

          return (
            <MainWrapper>
              <HeaderAndDrawer
                backButtonPath={backButtonPath}
                hideAppBarOnScroll={hideAppBarOnScroll}
              />
              {userHasAccess ? (
                <ErrorBoundary
                  FallbackComponent={props => (
                    <ContentContainer>
                      <EmptyState type="500" title="Ruh-roh!" {...props} />
                    </ContentContainer>
                  )}
                >
                  <Component {...routeProps} filters={filters} />
                  <ZendeskHelpLauncher />
                </ErrorBoundary>
              ) : (
                <PermissionDenied
                  message={
                    userHasNoBrandsOrTerritories
                      ? 'No brands accessible to this account.  Please contact your Jyve representative.'
                      : null
                  }
                />
              )}
            </MainWrapper>
          );
        }

        return (
          <Redirect
            to={{
              pathname: VIEWS.logout.path,
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
