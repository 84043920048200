import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';

import theme, { colors } from 'core/theme';

export const BottomUserInfo = styled.aside`
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  align-items: flex-start;
  margin: ${theme.spacing(1, 0)};
`;

export const DrawerHeader = styled.header`
  display: flex;
  align-items: flex-end;
  padding: ${theme.spacing(1, 1)};
  background-color: rgba(255, 255, 255, 0.05);
`;

export const CurrentBrandSelector = styled(Button)`
  font-size: 2rem;
  text-transform: none;
  font-weight: 600;
  line-height: 1.5;
  color: ${theme.palette.text.primary} !important;

  .MuiSvgIcon-root {
    color: ${theme.palette.primary.main};
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  z-index: ${theme.zIndex.appBar};
`;

export const DrawerContent = styled.div`
  padding: ${theme.spacing(8, 0, 3)};
  margin: ${theme.spacing(0, 12)};
  min-width: 300px;

  ${theme.breakpoints.down('sm')} {
    padding-top: ${theme.spacing(4)}px;
    margin: ${theme.spacing(0, 8)};
  }

  ${theme.breakpoints.down('xs')} {
    margin: ${theme.spacing(0, 2)};
  }

  .MuiListItemIcon-root {
    min-width: initial;
    margin-right: ${theme.spacing(2)}px;
  }

  .MuiListItem-root {
    padding-top: ${theme.spacing(3)}px;
    padding-bottom: ${theme.spacing(3)}px;

    &.active p {
      font-weight: 600;
    }

    &:last-child {
      border-bottom: 0 !important;
    }
  }

  .user-info-wrapper {
    background-color: ${colors.cloud};
    border-radius: ${theme.shape.borderRadius};
    padding: ${theme.spacing(0, 1)};
  }
`;

export const useHeaderAndDrawerStyles = makeStyles({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  drawerLogout: {
    color: 'gray',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: {
    display: 'flex',
  },
  title: {
    fontSize: '1.125rem',
  },
});
