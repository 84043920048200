/**
 * @name Walmart/LandingPage
 * @description
 * The landing page for walmart.jyve.com
 */

import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { walmartRoutes } from 'core/routes';
import analytics from 'core/analytics';
import { ReactComponent as JyveAndWalmartLogos } from 'assets/walmart/jyve-and-walmart.svg';
import { ReactComponent as ManageServiceIcon } from 'assets/walmart/manage-service.svg';
import { ReactComponent as ScheduleOverviewIcon } from 'assets/walmart/schedule-overview.svg';
import { ReactComponent as GiveFeedbackIcon } from 'assets/walmart/give-feedback.svg';
import auth from 'services/auth';
import Footer from 'layout/Footer';

import {
  Wrapper,
  Appbar,
  LandingPageHero,
  LandingPageContent,
} from '../styles';

export const LandingPage = () => {
  const history = useHistory();
  useEffect(() => {
    analytics.log('view', 'walmart_landing_page__view', {
      is_mobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
      window_size: {
        // not sure why we need this, but it was requested that we track it
        width: (window && window.innerWidth) || null,
        height: (window && window.innerHeight) || null,
      },
      brand_id: 'ZC9KJ',
    });

    // if the user is already logged in, send them to 'My Jyves'
    if (auth.isAuthenticated()) {
      history.replace(walmartRoutes.preload.path, {
        from: walmartRoutes.myJyves.path,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Appbar>
        <JyveAndWalmartLogos />
      </Appbar>

      <LandingPageHero>
        <Container maxWidth="xs">
          <Typography variant="h3">Assembly service</Typography>
          <Typography>
            Jyve is now a primary assembly
            <br />
            service provider for Walmart.
          </Typography>

          <Box my={4} mx="auto" maxWidth={300}>
            <Button
              component={Link}
              to={walmartRoutes.getstarted.path}
              variant="contained"
              color="secondary"
              size="large"
              disableElevation
            >
              Get started
            </Button>
          </Box>
        </Container>
      </LandingPageHero>

      <LandingPageContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <ManageServiceIcon />
            <Typography variant="subtitle1" color="primary">
              <strong>Manage service</strong>
            </Typography>
            <Typography>Add or cancel service</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <ScheduleOverviewIcon />
            <Typography variant="subtitle1" color="primary">
              <strong>Schedule overview</strong>
            </Typography>
            <Typography>View your assembly service schedule</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <GiveFeedbackIcon />
            <Typography variant="subtitle1" color="primary">
              <strong>Give feedback</strong>
            </Typography>
            <Typography>How did you like Jyve&apos;s service?</Typography>
          </Grid>
        </Grid>
      </LandingPageContent>

      <Footer />
    </Wrapper>
  );
};
