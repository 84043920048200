export const formatNumber = (num: string | number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

/**
 * @name formatPhoneNumber
 * @param phoneNumber string
 * @returns string
 * @description
 * Takes in a phone number (5505555050) and returns it formatted (550) 555-5050.
 */
export function formatPhoneNumber(phoneNumber: string) {
  let phone = phoneNumber.replace(/\D/g, '');
  const match = /^(1|)?(\d{3})(\d{3})(\d{4})$/.exec(phone);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    phone = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phone;
}
