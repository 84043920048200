/**
 * @name useInsightsFilters
 * @description
 * A hook that grabs the query parameters being
 * used in the URL and converts them into filters
 * that our UI understands
 */

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { createReportParametersFromFilters } from 'helpers/http';
import { getFiltersForInsights } from 'helpers/userAccessAndFiltersUtils';
import { useCurrentBrand } from 'helpers/hooks';

import {
  InsightsFilterStructureForClient,
  InsightsFilterStructureForApi,
} from 'types';

export function useInsightsFilters(
  formatForAPI: true
): InsightsFilterStructureForApi;
export function useInsightsFilters(
  formatForAPI?: false
): InsightsFilterStructureForClient;
export function useInsightsFilters(formatForAPI = false) {
  const { search } = useLocation();
  const currentBrand = useCurrentBrand();

  const [filters, setFilters] = useState<InsightsFilterStructureForClient>(
    () => {
      const initialState = getFiltersForInsights(currentBrand, search);
      return initialState;
    }
  );

  useEffect(() => {
    setFilters(getFiltersForInsights(currentBrand, search));
  }, [search, currentBrand]);

  return formatForAPI ? createReportParametersFromFilters(filters) : filters;
}
