import styled from 'styled-components/macro';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import theme, { colors } from 'core/theme';

export const MainWrapper = styled(DialogContent)`
  padding: ${theme.spacing(2, 3)};
`;

export const DeliveryAndEstimatedCostWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacing(1)}px;
`;

export const EstimatedCost = styled.div`
  margin-top: ${theme.spacing(1)}px;
  margin-left: ${theme.spacing(1)}px;
  flex: 0;
  align-items: flex-end;
`;

export const BottomButtonsWrapper = styled(DialogActions)`
  padding: ${theme.spacing(2, 3, 3)};
`;

export const BottomAsterisk = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${theme.spacing(2)}px ${theme.spacing(2)}px;
  margin-top: -${theme.spacing(1)}px;
  color: ${theme.palette.text.secondary};

  p {
    font-size: 0.7rem;
  }
`;

export const DisclaimerBox = styled.div`
  padding: ${theme.spacing(1, 2)};
  background-color: ${colors.cloud};
`;
