/**
 * @name domainOverride
 * @author Bruce MacFarlane
 * @description This is the core logic for how we set up domain overrides.
 * It checks for (or sets) a domain override URL in a cookie (but only on non-prod environments).
 * Allows user to select an api domain different than the default
 * for the environment.  Access to the UI for this feature is hidden behind
 * a feature flag.  When the user makes a selection, we redirect them to logout
 * and then save the cookie after logout.  For all subsequent requests, we use
 * the domain set in the cookie within our HTTP handler.  This will not work on
 * PROD environment.
 */

import Cookies from 'js-cookie';

import { API_DOMAINS, ENV, ENV_PROD } from 'core/config';

export const USER_SELECTED_API_DOMAIN = 'USER_SELECTED_API_DOMAIN';

/**
 * @name checkForQADebugAPIDomainOverride
 * @description
 * Retrieves a  domain override cookie set on the users browser, but only in non-prod environments
 */
export const checkForQADebugAPIDomainOverride = (): string | null => {
  const domainOverrideCookieVal = Cookies.get(USER_SELECTED_API_DOMAIN);
  const notProd = ENV !== ENV_PROD; // this is just checking the frontend env
  const allowed = domainOverrideCookieVal && notProd;
  return allowed && domainOverrideCookieVal ? domainOverrideCookieVal : null;
};

/**
 * @name isConnectedToProdAPIFromNonProdEnv
 * @description Checks if prod api is connected to a non-prod frontend env
 * which can lead to bad bad things.
 */
export const isConnectedToProdAPIFromNonProdEnv = (): boolean => {
  return (
    ENV !== ENV_PROD &&
    checkForQADebugAPIDomainOverride() === API_DOMAINS[ENV_PROD]
  );
};

/**
 * @name setQADebugAPIDomainOverride
 * @description
 * Called after logout to set the cookie.  We have to call it after logout so
 * the user can be fully logged out of the current environment before making
 * the login in request to the new one.
 */
export const setQADebugAPIDomainOverride = (val: string) => {
  Cookies.set(USER_SELECTED_API_DOMAIN, val, { expires: 1 });
};

/**
 * @name deleteQADebugAPIDomainOverride
 * @description
 * Called from login screen if domain is set and user wants to remove it.
 */
export const deleteQADebugAPIDomainOverride = () => {
  Cookies.remove(USER_SELECTED_API_DOMAIN);
};
