/**
 * @name Walmart/VerifyPhone
 * @description
 * Verify phone number with the code we just sent you
 */

import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { TextField, showErrorOnBlur } from 'mui-rff';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { receiveLoggedInUserInfo } from 'state/user/actions';
import auth from 'services/auth';
import analytics from 'core/analytics';
import { walmartRoutes } from 'core/routes';
import { formatPhoneNumber } from 'helpers/formatting';
import { CurrentUserContext, useCurrentUser } from 'helpers/hooks';
import PublicLayout from 'layout/PublicLayout';
import { isMissingUserProfileInfo } from 'helpers';

interface FormValues {
  code: string;
}

type Errors = {
  [P in keyof FormValues]?: string;
};

const validate = (values: FormValues) => {
  const errors: Errors = {};

  if (!values.code) {
    errors.code = 'Please enter the code we sent you';
  }

  return errors;
};

export const VerifyPhonePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string | null>(null);
  const [isResending, setIsResending] = useState(false);
  const { updateCurrentUserFromLocalStorage } = useContext(CurrentUserContext);
  const from = history.location?.state?.from || walmartRoutes.myJyves.path;
  const currentUser = useCurrentUser();

  const mobile = history?.location?.state?.mobile;
  const skipCompleteProfile = history?.location?.state?.skipCompleteProfile; // used when user already comes from Complete Profile and is now just verifying their number
  const is_mobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  useEffect(() => {
    if (mobile) {
      // so long as we're not routing them away from this screen
      analytics.log('view', 'walmart_sms_verify__view', {
        is_mobile,
        brand_id: 'ZC9KJ',
        phone_number: mobile, // TODO: not liking us storing phone numbers in Segment events
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // If a user tries to open up the verify route without a phone number being
  // entered in the previous step, we just move them to the get started page
  if (!mobile) {
    history.push(walmartRoutes.getstarted.path);
    return null;
  }

  const onSubmit = async (values: FormValues) => {
    setError(null);

    try {
      let cleanedNumber = mobile.replace(/\D/g, '');
      if (cleanedNumber.startsWith('1'))
        cleanedNumber = cleanedNumber.substring(1);
      const { userInfo } = await auth.verifyPhoneNumberWithCode(
        cleanedNumber, // this could be formatted if coming back in user payload, just need to strip extra chars
        values.code
      );

      // If a user is logging in by phone, this will be the first time we
      // have their info, so we need to identify them
      if (!currentUser && userInfo.email) {
        // since user could already be logged in and just adding phone, we're first checking for active user before identifying
        analytics.identify(userInfo.email);
      }

      dispatch(receiveLoggedInUserInfo(userInfo));

      if (updateCurrentUserFromLocalStorage) {
        updateCurrentUserFromLocalStorage();
      }

      const missingProfileInfo = isMissingUserProfileInfo(userInfo);

      if (missingProfileInfo && !skipCompleteProfile) {
        history.replace(walmartRoutes.completeYourProfile.path, {
          from,
          userInfo,
        });
      } else {
        history.push(walmartRoutes.preload.path, {
          from,
        });
      }
    } catch (err) {
      setError(err.message || 'Unknown error occurred');
    }
  };

  const onResendCode = async () => {
    try {
      setIsResending(true);
      await auth.sendPhoneVerificationCode(mobile);
      enqueueSnackbar('Code has been successfully resent.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      analytics.log('track', 'walmart_sms_verify__resend', {
        is_mobile,
        brand_id: 'ZC9KJ',
        phone_number: mobile, // TODO: not liking us storing phone numbers in Segment events
      });
      setIsResending(false);
    } catch (err) {
      setError(err.message || 'Unknown error occurred');
    }
  };

  return (
    <PublicLayout>
      <Paper elevation={4}>
        <Box py={2} px={2}>
          <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, invalid, submitting }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Typography variant="subtitle1">
                  <strong>We’ve sent you a code</strong>
                </Typography>
                <Box my={1}>
                  <Typography>You’ll receive a code on</Typography>
                  <Typography color="primary">
                    <strong>{formatPhoneNumber(mobile)}</strong>
                  </Typography>
                </Box>
                <Box my={2}>
                  <TextField
                    name="code"
                    label="Code"
                    placeholder="123456"
                    autoCorrect="off"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 6,
                    }}
                    disabled={submitting}
                    showError={showErrorOnBlur}
                    required
                    autoFocus
                  />
                </Box>

                <Box my={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={invalid || submitting}
                    startIcon={
                      submitting ? (
                        <CircularProgress
                          size={16}
                          color="inherit"
                          disableShrink
                        />
                      ) : null
                    }
                    fullWidth
                  >
                    Verify
                  </Button>
                </Box>

                <Button
                  variant="contained"
                  color="default"
                  size="large"
                  onClick={onResendCode}
                  disabled={isResending}
                  startIcon={
                    isResending ? (
                      <CircularProgress
                        size={16}
                        color="inherit"
                        disableShrink
                      />
                    ) : null
                  }
                  fullWidth
                >
                  Resend code
                </Button>
              </form>
            )}
          />

          {!!error && (
            <Box mt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </Box>
      </Paper>
    </PublicLayout>
  );
};
