/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @name ZendeskHelpLauncher
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description
 * Setup for handling Zendesk Help Widget.
 * Takes care of loading script, uses a custom-rolled launcher button to trigger widget.
 * Widget that opens is anchored to the lower-right of screen.  I tried to move it to align
 * with the button even if it's in a max-width float, but Zendesk's 'offset' functionality
 * does not like being set after load.
 */

import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

import MuiContainer from '@material-ui/core/Container';
import MuiButton from '@material-ui/core/Button';
import ModeCommentTwoToneIcon from '@material-ui/icons/ModeCommentTwoTone';

import theme from 'core/theme';
import analytics from 'core/analytics';
import { useCurrentUser, useCurrentBrand } from 'helpers/hooks';
import { ENV, ENV_PROD, FEATURE_FLAGS } from 'core/config';
import ldWrapper from 'core/ldWrapper';

declare global {
  interface Window {
    zESettings: any;
    zE: any;
  }
}

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
`;

const Container = styled(MuiContainer)`
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled(MuiButton)`
  position: fixed;
  bottom: ${theme.spacing(2)}px;
  &.raise {
    bottom: ${theme.spacing(10)}px;
  }
  margin-bottom: env(safe-area-inset-bottom);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &:hover {
    background-color: ${theme.palette.primary
      .main}; // overrides button bg opacity change on hover
  }
  border-radius: 25px;
  pointer-events: auto;
`;

const ZendeskHelpLauncher = () => {
  const location = useLocation();
  const currentUser = useCurrentUser();
  const currentBrand = useCurrentBrand();

  const path = location.pathname;
  // MyJyves and Job Templates pages have filters at bottom of screen we want to get out of the way of
  const myJyvesPath = /\/brands\/.+\/jobs/;
  const templatesPath = /\/brands\/.+\/templates/;
  const includesLowerFilters =
    myJyvesPath.test(path) || templatesPath.test(path);

  const setLauncherVisibility = (show = true) => {
    const el = document.querySelector(
      '.zendesk-launcher-button'
    ) as HTMLElement;
    if (el) {
      el.style.opacity = show ? '1' : '0';
    }
  };

  const handleWidgetUserEvent = (userEvent: any) => {
    const { action, category, properties } = userEvent;
    analytics.log('track', category, { ...properties, action });
  };

  const loadZendeskScript = () => {
    if (window.zE) return; // already loaded
    const key =
      ENV === ENV_PROD
        ? 'cc465634-3feb-4328-97a8-ecbdcd104cd2'
        : '5cbf7b9d-59b5-4b8c-983b-4bd0f5573cdb';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.onload = () => {
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide');
        setLauncherVisibility();
      });

      window.zE('webWidget:on', 'userEvent', handleWidgetUserEvent);
    };
    script.onerror = () => {
      // no-op
    };
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    document.body.appendChild(script);
  };

  const handleOpenWidget = () => {
    if (!window.zE) return;
    if (currentUser) {
      const name = currentUser.name || '';
      const email = !currentUser.email.includes('@sms.jyve.com')
        ? currentUser?.email
        : '';
      if (currentBrand) {
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            contactForm: {
              fields: [
                {
                  id: 'key:360033787194', // ID for Brand text field in PROD Zendesk setup
                  prefill: { '*': currentBrand.client_name },
                },
              ],
            },
          },
        });
      }
      window.zE('webWidget', 'prefill', {
        name: {
          value: name,
        },
        email: {
          value: email,
        },
      });
    }
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    setLauncherVisibility(false);
  };

  useEffect(() => {
    loadZendeskScript();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(setLauncherVisibility, 1000);
  }, []);

  if (!ldWrapper.hasAccess(FEATURE_FLAGS.zendeskHelpWidgetEnabled)) return null;

  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Button
          className={`zendesk-launcher-button ${
            includesLowerFilters ? 'raise' : ''
          }`}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ModeCommentTwoToneIcon />}
          onClick={handleOpenWidget}
        >
          Help
        </Button>
      </Container>
    </Wrapper>
  );
};

export default ZendeskHelpLauncher;
