/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Slide from '@material-ui/core/Slide';

import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import SignalCellIcon from '@material-ui/icons/SignalCellularAltTwoTone';
import NotesIcon from '@material-ui/icons/NotesTwoTone';
import ImportExportIcon from '@material-ui/icons/ImportExportTwoTone';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import { ReactComponent as LogoWordmark } from 'assets/logo-wordmark-on-cloud.svg';
import { AssignmentIcon, JobTemplatesIcon, LogoutIcon } from 'assets/icons';
import { CustomLinkWithRef } from 'components/CustomLink';
import { VIEWS } from 'core/config';
import { primaryGradient } from 'core/theme';
import {
  useCurrentBrand,
  useCurrentUser,
  useHeaderTitle,
  useScrollTriggerForAppBar,
} from 'helpers/hooks';
import { injectSlugsToPath } from 'helpers/routing';
import analytics from 'core/analytics';
import AuthService from 'services/auth';
import QaDebugApiDomainSelector from 'components/QaDebugApiDomainSelector';
import ScheduleJobsDialog from 'dialogs/ScheduleJobs';

import BrandSelector from './BrandSelector';
import {
  useHeaderAndDrawerStyles,
  DrawerHeader,
  DrawerWrapper,
  DrawerContent,
} from './styles';

const GradientTopBar = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: ${primaryGradient};
  z-index: 1300;
`;

interface Props {
  backButtonPath?: string;
  hideAppBarOnScroll?: boolean;
}

const HeaderAndDrawer = (props: Props) => {
  const [subDomain] = window.location.host.split('.');
  const params = useParams();
  const classes = useHeaderAndDrawerStyles();
  const currentBrand = useCurrentBrand();
  const currentUser = useCurrentUser();
  const { headerTitle } = useHeaderTitle();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const showAppBar = useScrollTriggerForAppBar();

  // TODO: Move access control into CASL
  const selfServeEnabled = !!currentBrand?.self_serve;
  // TODO: Remove hard coding
  const isOnWalmartSubdomain = subDomain === 'walmart';

  useEffect(() => {
    if (isDrawerOpen) {
      analytics.log('view', 'menu_drawer__view');
    }
  }, [isDrawerOpen]);

  if (!AuthService.isAuthenticated()) return null;

  // Handle the back button path
  let { backButtonPath } = props;
  if (backButtonPath) {
    backButtonPath = injectSlugsToPath(backButtonPath, params);
  }

  let selfServeMenuItems = null; // self serve null by default
  if (selfServeEnabled) {
    selfServeMenuItems = (
      <>
        <ListItem
          button
          divider
          component={NavLink}
          to={
            currentBrand
              ? `${VIEWS.brands.path}/${currentBrand.public_id}/jobs`
              : VIEWS.home.path
          }
          onClick={() => setIsDrawerOpen(false)}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText secondary={'My Jyves'} />
        </ListItem>
        <ListItem
          button
          divider
          component={NavLink}
          to={`${VIEWS.brands.path}/${currentBrand.public_id}/templates`}
          onClick={() => setIsDrawerOpen(false)}
          id="main-menu-templates-link"
        >
          <ListItemIcon>
            <JobTemplatesIcon />
          </ListItemIcon>
          <ListItemText secondary={'Task Lists'} />
        </ListItem>
      </>
    );
  }

  const { hideAppBarOnScroll } = props;

  return (
    <>
      <GradientTopBar />
      <DrawerWrapper>
        <Slide
          appear={false}
          direction="down"
          in={!hideAppBarOnScroll || !showAppBar}
        >
          <AppBar elevation={8}>
            <Container maxWidth="lg">
              <Toolbar className={classes.toolbar} disableGutters>
                <Box flex="0 0 auto">
                  {!backButtonPath ? (
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      onClick={() => setIsDrawerOpen(true)}
                      edge="start"
                      className={clsx(classes.menuButton)}
                      id="header-hamburger-button"
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      component={CustomLinkWithRef}
                      to={backButtonPath}
                      color="primary"
                      aria-label="go back"
                      edge="start"
                      className={clsx(classes.menuButton)}
                      id="header-back-button"
                    >
                      <BackIcon />
                    </IconButton>
                  )}
                </Box>

                <Box
                  flex="1 1 auto"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  <Typography className={classes.title} noWrap>
                    {headerTitle || currentBrand?.name}
                  </Typography>
                </Box>

                <Hidden smDown>
                  <Box
                    position="absolute"
                    left="50%"
                    top={26}
                    marginLeft="-50px"
                  >
                    <LogoWordmark />
                  </Box>
                </Hidden>

                <Box flex="0 0 auto">
                  <Button
                    component={CustomLinkWithRef}
                    hash="schedule"
                    color="secondary"
                    variant="contained"
                    size="large"
                    startIcon={<FlashOnIcon />}
                  >
                    Add Jyves
                  </Button>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </Slide>

        <SwipeableDrawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onOpen={() => setIsDrawerOpen(true)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerContent>
            <DrawerHeader>
              <BrandSelector />
            </DrawerHeader>
            <List>
              {selfServeMenuItems}

              {!isOnWalmartSubdomain && (
                <>
                  <ListItem
                    button
                    divider
                    component={NavLink}
                    to={
                      currentBrand
                        ? `${VIEWS.brands.path}/${currentBrand.public_id}/insights`
                        : VIEWS.home.path
                    }
                    onClick={() => setIsDrawerOpen(false)}
                    id="main-menu-home-link"
                  >
                    <ListItemIcon>
                      <SignalCellIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText secondary={'Insights'} />
                  </ListItem>

                  <ListItem
                    button
                    divider
                    component={NavLink}
                    to={
                      currentBrand
                        ? `${VIEWS.brands.path}/${currentBrand.public_id}/notes`
                        : VIEWS.home.path
                    }
                    onClick={() => setIsDrawerOpen(false)}
                    id="main-menu-notes-link"
                  >
                    <ListItemIcon>
                      <NotesIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText secondary={'Notes'} />
                  </ListItem>

                  <ListItem
                    button
                    divider
                    component={NavLink}
                    to={
                      currentBrand
                        ? `${VIEWS.brands.path}/${currentBrand.public_id}/export`
                        : VIEWS.home.path
                    }
                    onClick={() => setIsDrawerOpen(false)}
                    id="main-menu-export-link"
                  >
                    <ListItemIcon>
                      <ImportExportIcon color="primary" />
                    </ListItemIcon>

                    <ListItemText secondary={'Export Data'} />
                  </ListItem>
                </>
              )}
            </List>

            {currentUser?.name && (
              <List className="user-info-wrapper">
                <ListItem>
                  {!!currentUser?.avatar && (
                    <ListItemAvatar>{currentUser?.avatar}</ListItemAvatar>
                  )}
                  <ListItemText
                    secondary={currentUser?.name}
                    primary={currentUser?.email}
                  />
                </ListItem>
              </List>
            )}

            <List>
              <ListItem
                button
                component={NavLink}
                to={'/logout'}
                onClick={() => setIsDrawerOpen(false)}
                id="main-menu-logout-link"
              >
                <ListItemIcon>
                  <LogoutIcon color="inherit" />
                </ListItemIcon>

                <ListItemText
                  secondary={'Log out'}
                  secondaryTypographyProps={{ color: 'textSecondary' }}
                />
              </ListItem>
            </List>
          </DrawerContent>

          <Box flex={0} pb={3} px={2}>
            <QaDebugApiDomainSelector />

            <Box textAlign="center" mt={1}>
              <Typography variant="caption" color="textSecondary">
                Copyright @{new Date().getFullYear()} Jyve
              </Typography>
            </Box>
          </Box>
        </SwipeableDrawer>

        {/* We use <Toolbar /> here to create the same negative space that the appbar uses */}
        <Toolbar />
      </DrawerWrapper>
      <ScheduleJobsDialog />
    </>
  );
};

export default HeaderAndDrawer;
