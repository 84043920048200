/**
 * @name useHeaderTitle
 * @description
 * A hook that handles what we want to display in the title
 * portion of our AppBar, syncing that to the document title.
 */

import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDocumentTitle } from 'helpers/hooks';

interface ContextProps {
  headerTitle: string | null;
  setHeaderTitle: Dispatch<SetStateAction<string | null>>;
}

const HeaderTitleContext = createContext<Partial<ContextProps>>({});

interface ProviderProps {
  children?: ReactNode;
}

export const HeaderTitleProvider = ({ children }: ProviderProps) => {
  const [headerTitle, setHeaderTitle] = useState<string | null>(null);

  useDocumentTitle(headerTitle);

  return (
    <HeaderTitleContext.Provider value={{ headerTitle, setHeaderTitle }}>
      {children}
    </HeaderTitleContext.Provider>
  );
};

export const useHeaderTitle = () => {
  const ctx = useContext(HeaderTitleContext);
  return ctx;
};
