/**
 * @name TextField
 * @author Magnus <magnus@jyve.com>
 * @description
 * A TextField, with a couple of bells and whistles, ready to get plugged into react-final-form's <Field />
 *
 * @todo Move this to bit.dev
 */

import React from 'react';
import styled from 'styled-components/macro';
import { FieldRenderProps } from 'react-final-form';

import MuiTextField from '@material-ui/core/TextField';

const HelperText = styled.span`
  display: flex;
  justify-content: flex-end;

  .error-message {
    flex: 1;
  }

  .helper-text {
    flex: 1;
  }

  .character-count {
    flex: 0;
  }
`;

interface Props extends FieldRenderProps<string> {
  characterLimit?: number;
}

export const TextField: React.FC<Props> = ({
  input,
  meta,
  characterLimit,
  helperText,
  ...rest
}: Props) => {
  const isErrored = !!meta.error && (meta.touched || input.value.length > 0);
  let adjustedHelperText;
  if (helperText || isErrored || !!characterLimit) {
    adjustedHelperText = (
      <HelperText>
        {isErrored && <span className="error-message">{meta.error}</span>}
        {!isErrored && helperText && (
          <span className="helper-text">{helperText}</span>
        )}
        {!!characterLimit && (
          <span className="character-count">
            {input.value.length}/{characterLimit}
          </span>
        )}
      </HelperText>
    );
  }

  return (
    <MuiTextField
      inputProps={{
        maxLength: characterLimit || '',
      }}
      helperText={adjustedHelperText}
      variant="outlined"
      fullWidth
      error={isErrored}
      {...input}
      {...rest}
    />
  );
};
