import React from 'react';
import styled from 'styled-components/macro';

import Container from '@material-ui/core/Container';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import Popover, { PopoverProps } from '@material-ui/core/Popover';

import theme, { colors } from 'core/theme';

/**
 * @name MainWrapper
 * @description
 * The main wrapper, wrapping our header, main content area and footer
 * The 'margin-bottom' value works exposing the Footer underneath the main content.
 * This value and the Footer height should match.
 * See: /src/components/Footer/index.tsx (css height)
 */
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: ${theme.palette.background.default};
`;

/**
 * @name ContentContainer
 * @description
 * A material-ui <Container /> that includes some basic css additions
 */
export const ContentContainer = styled(Container)`
  padding-top: ${theme.spacing(2)}px;
  padding-bottom: ${theme.spacing(6)}px;

  ${theme.breakpoints.up('sm')} {
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(10)}px;
  }
`;

/**
 * @name GutterControl
 * @description
 * Handles the correct gutter setting for all page contents
 *
 * @todo Deprecate this in favor of using the built-in <Container /> instead
 */
export const GutterControl = styled.main<{
  noRightGutter?: boolean;
  reportDetail?: boolean;
}>`
  padding: 0 ${theme.spacing(2)}px;
  ${theme.breakpoints.up('sm')} {
    padding: 0 ${theme.spacing(3)}px;
  }

  ${props =>
    props.noRightGutter &&
    `
    padding-right: 0 !important;
  `}
  ${props =>
    props.reportDetail &&
    `
    ${theme.breakpoints.down('sm')} {
      padding-right: 0 !important;
    }
  `}
`;

/**
 * @name InputPopover
 * @description
 * When we use fake <TextField /> components (for our territories and date
 * range selectors as an example), we need a slightly customized <Popover />
 * that morphs over to being fullscreen on mobile without the need for a
 * re-render. This component handles that.
 */

export const InputPopover = styled(props => (
  <Popover {...props} marginThreshold={0} />
))<PopoverProps>`
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${theme.shape.borderRadius}px;
    min-width: 300px;
    height: calc(100vh - ${theme.spacing(2)}px);
    max-height: 600px;

    ${theme.breakpoints.down('xs')} {
      width: 100vw;
      height: 100%;
      max-width: 100vw;
      max-height: 100%;
      top: 0 !important;
      left: 0 !important;
      margin: 0;
    }
  }
`;

const BoltIconWrapper = styled.div`
  color: white;
  border-radius: 50%;
  background-color: ${colors.infrared};
  font-size: 16px;
  height: 1em;
  width: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: sub;
  margin-left: ${theme.spacing(0.5)}px;

  svg {
    padding: 2px;
  }
`;

interface Props {
  style?: React.CSSProperties;
}

export const BoltIcon = (props: Props) => (
  <BoltIconWrapper {...props}>
    <FlashOnIcon fontSize="inherit" />
  </BoltIconWrapper>
);
