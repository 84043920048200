import './instrument';
import './index.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Loader from 'components/Loader';

import analytics from 'core/analytics';
import ldWrapper from 'core/ldWrapper';
import { LAUNCH_DARKLY_ID } from 'core/config';
import { getLaunchDarklyFormattedUserInfo } from 'core/ldWrapper/helpers';

import App from './App';
import store from './state/store';

analytics.init();

ldWrapper.init(
  getLaunchDarklyFormattedUserInfo(null, null) || { anonymous: true },
  LAUNCH_DARKLY_ID,
  /**
   * To make sure feature flags are loaded, we wait till LD is initialized
   * before rendering.  Since our default setup uses a localStorage bootstrap
   * this should generally be quick.
   */
  () => {
    ReactDOM.render(
      <React.Suspense fallback={<Loader fullPage />}>
        <Provider store={store}>
          <App />
        </Provider>
      </React.Suspense>,
      document.getElementById('root')
    );
  }
);
