/**
 * @name useDocumentTitle
 * @description
 * Changing of the document title through a hook
 */

import { useEffect } from 'react';

export const useDocumentTitle = (title: string | null) => {
  const affix = 'Jyve Partner Platform';

  useEffect(() => {
    if (!title || title.length <= 0) {
      document.title = affix;
    } else {
      document.title = `${title} | ${affix}`;
    }
  }, [title]);
};
