/**
 * @name WalmartRoutes
 * @description
 * For now, this only holds a landing page on walmart.jyve.com, but later on
 * we will have a separate login / signup and possibly password reset since
 * our implementation with Walmart could add the ability to login with a
 * phone number, etc.
 */

import {
  CompleteYourProfilePage,
  GetStartedPage,
  LandingPage,
  VerifyPhonePage,
} from 'client-specific/walmart/containers';

import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { SentryMonitoredRoute } from '../sentry';

const AppPreload = React.lazy(() => import('containers/AppPreload'));
const ChangePasswordContainer = React.lazy(
  () => import('containers/ChangePassword')
);
const ForgotPasswordContainer = React.lazy(
  () => import('containers/ForgotPassword')
);
const LogoutContainer = React.lazy(() => import('containers/Logout'));
const MyJyvesContainer = React.lazy(() => import('containers/MyJyves'));
const TaskListsContainer = React.lazy(() => import('containers/TaskLists'));
const TaskListDetails = React.lazy(
  () => import('containers/TaskLists/Details')
);

interface Routes {
  [key: string]: {
    path: string;
    component: React.ComponentType<unknown>;
    isPublic?: boolean;
    pageTitle?: string;
    backButtonPath?: string;
  };
}

// hardcoded to Walmart's public brand ID to force all routes to Walmart
const walmartHardcodedBrandsPrefix = '/brands/ZC9KJ';

export const walmartRoutes: Routes = {
  home: {
    path: '/',
    component: LandingPage,
    isPublic: true,
  },
  getstarted: {
    path: '/login',
    component: GetStartedPage,
    isPublic: true,
  },
  forgotPass: {
    path: '/forgot_password',
    component: ForgotPasswordContainer,
    isPublic: true,
  },
  changePass: {
    path: '/change_password',
    component: ChangePasswordContainer,
    isPublic: true,
  },
  verifyPhone: {
    path: '/login/verify',
    component: VerifyPhonePage,
    isPublic: true,
  },
  completeYourProfile: {
    path: '/login/welcome',
    component: CompleteYourProfilePage,
    isPublic: true,
  },
  logout: {
    path: '/logout',
    component: LogoutContainer,
    isPublic: true,
  },
  preload: {
    path: '/preload',
    component: AppPreload,
    isPublic: true,
  },
  myJyves: {
    path: `${walmartHardcodedBrandsPrefix}/jobs`,
    component: MyJyvesContainer,
    pageTitle: 'My Jyves',
  },
  taskLists: {
    path: `${walmartHardcodedBrandsPrefix}/templates`,
    component: TaskListsContainer,
    pageTitle: 'Task Lists',
  },
  taskList: {
    path: `${walmartHardcodedBrandsPrefix}/templates/:templateId`,
    component: TaskListDetails,
    pageTitle: 'Task List',
    backButtonPath: '/brands/ZC9KJ/templates',
  },
};

export const WalmartRoutes = () => {
  return (
    <Switch>
      {Object.entries(walmartRoutes).map(([key, { isPublic, ...route }]) => {
        const Component = isPublic ? SentryMonitoredRoute : PrivateRoute;
        return <Component key={`walmart-route-${key}`} exact {...route} />;
      })}

      {/* This is only here because our original app preload redirects to /login */}
      <SentryMonitoredRoute path="/login">
        <Redirect to={walmartRoutes.home.path} />
      </SentryMonitoredRoute>
      <SentryMonitoredRoute path="" component={() => <h1>Page Not Found</h1>} />
    </Switch>
  );
};
