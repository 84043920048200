/**
 * @name separateThousands
 * @author Magnus <magnus@jyve.com>
 * @description
 * Add a comma (,) between thousands
 *
 * @param {number} value The number we want to separate thousands
 * @returns {string}
 *
 * @example
 * // returns '1,000'
 * separateThousands(1000);
 *
 * @example
 * // returns '999'
 * separateThousands(999)
 */

export default function separateThousands(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
