import React from 'react';
import styled from 'styled-components/macro';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import theme from 'core/theme';

const DialogTitleWrapper = styled(MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${theme.spacing(2, 2, 1, 3)};
`;

type Props = {
  title: string;
  subTitle?: string;
  onClose?: () => void;
  showCloseIcon?: boolean;
};

export function DialogTitle({
  title,
  subTitle,
  onClose,
  showCloseIcon = true,
}: Props) {
  return (
    <DialogTitleWrapper disableTypography>
      <Box>
        <Typography
          variant={subTitle ? 'body1' : 'subtitle1'}
          component="div"
          gutterBottom
        >
          <strong>{title}</strong>
        </Typography>

        {!!subTitle && (
          <Typography color="textSecondary" variant="caption" component="div">
            {subTitle}
          </Typography>
        )}
      </Box>

      {showCloseIcon && (
        <IconButton aria-label="close-scheduling-dialog" onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      )}
    </DialogTitleWrapper>
  );
}
