/* eslint-disable no-param-reassign */
/**
 * @name useScrollTriggerForAppBar
 * @author Magnus <magnus@jyve.com>
 * @description
 * This is a modified version of material-ui's useScrollTrigger() that adds a padding to how much
 * the user has scrolled up before showing the app bar again.
 * For original hook, see: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/useScrollTrigger/useScrollTrigger.js
 */

import { useRef, useState, useEffect } from 'react';

declare const window: any;
const THRESHOLD = 100; // how many pixels from the top of the page do we need to scroll before hiding the app bar
const DIRECTION_CHANGE_THRESHOLD = -60; // how many pixels do we need to scroll up before showing the app bar again

function getTrigger(
  scrollPosition: React.MutableRefObject<number | undefined>,
  lastDirectionChangePosition: React.MutableRefObject<number | undefined>
) {
  const previous = scrollPosition.current;
  const lastDirectionChange = lastDirectionChangePosition.current;

  if (window) {
    // Get vertical scroll
    scrollPosition.current =
      window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;
  }

  if (!scrollPosition.current) return false;

  if (previous !== undefined) {
    if (scrollPosition.current < previous) {
      // We're scrolling up

      if (lastDirectionChange === undefined) {
        lastDirectionChangePosition.current = previous;
      }

      if (
        lastDirectionChange !== undefined &&
        scrollPosition.current - lastDirectionChange <
          DIRECTION_CHANGE_THRESHOLD
      ) {
        return false; // Show the app bar
      }

      return scrollPosition.current > THRESHOLD;
    }
  }

  // We're scrolling down
  lastDirectionChangePosition.current = undefined;

  return scrollPosition.current > THRESHOLD;
}

export function useScrollTriggerForAppBar() {
  const scrollPosition = useRef<number | undefined>();
  const lastDirectionChangePosition = useRef<number | undefined>();
  const [trigger, setTrigger] = useState(() =>
    getTrigger(scrollPosition, lastDirectionChangePosition)
  );

  useEffect(() => {
    const handleScroll = () => {
      setTrigger(getTrigger(scrollPosition, lastDirectionChangePosition));
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return trigger;
}
