import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { upperFirst } from 'lodash';
import { useFormState } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { useRollbar } from '@jyveapp/react-component-library';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';

import { getFormattedDateRange } from 'helpers/date';
import JobsService from 'services/jobs';
import { useCurrentBrand } from 'helpers/hooks';
import { DialogTitle } from 'dialogs/components';
import { ScheduleJobsFormValues } from 'types';
import { JobsRequestResponse } from 'types/api';
import analytics from 'core/analytics';
import StoreScheduleCard from 'cards/StoreScheduleCard';
import { isAxiosError } from 'core/http';

import { FormOrReviewProps } from './FormOrReview';
import {
  MainWrapper,
  DeliveryAndEstimatedCostWrapper,
  EstimatedCost,
  BottomAsterisk,
  BottomButtonsWrapper,
  DisclaimerBox,
} from '../styles';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface Props extends FormOrReviewProps {
  onGoBack: () => void;
}

export default function ScheduleJobsReview({
  isSubmitting,
  onGoBack,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { values } = useFormState<ScheduleJobsFormValues>();
  const currentBrand = useCurrentBrand();
  const rollbar = useRollbar();
  const [isFetchingDryRun, setIsFetchingDryRun] = useState(true);
  const [dryRunErrored, setDryRunErrored] = useState(false);
  const [dryRunData, setDryRunData] = useState<JobsRequestResponse | null>(
    null
  );

  let isOfTypeDelivery = false;
  let estimatedCost: string | null = null;
  const brandPublicId = currentBrand.public_id || '';
  const { stores, recurring } = values;

  // Dry-run to get estimated cost, job duration and more
  const fetchDryRunData = () => {
    JobsService.scheduleJobs({ ...values, brandPublicId }, { dryRun: true })
      .then(data => setDryRunData(data))
      .catch(error => {
        setDryRunErrored(true);

        // TODO: Use getHumanFriendlyError() when it's ready
        // Default to whatever message we get from the error instance
        let errorMessage =
          error.message ||
          'Sorry, but something seems to have gone wrong. Please try again later.';

        if (isAxiosError(error)) {
          if (error.code === 'ECONNABORTED') {
            errorMessage = 'Connection timed out. Please try again later.';
          } else if (error.response?.status === 500) {
            errorMessage =
              'Unable to communicate with server. Please try again later.';
          }
        }

        try {
          const { detail } = error.response.data;
          if (detail) {
            errorMessage = detail;
          }
        } catch (e) {
          rollbar.error(
            "[Schedule job]: API didn't return the correct format for an error detail!",
            e
          );
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });

        console.error('[Review]', error);
      })
      .finally(() => {
        setIsFetchingDryRun(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchDryRunData, []);

  // Merge what we know about each job based on
  // 1.) the data given by the user in the previous step
  let jobs = stores?.map(store => ({
    store_location_id: store.value,
    store_location_address: store.address,
    brand_name: currentBrand.name,
    store_location_self_identity: store.primary_self_identity,
    banner_name: store.chain_name,
    type: null as string | null,
    time_window: null as string | null,
    estimated_time: undefined as number | undefined,
    estimated_cost: null as string | null,
    error: null as string | null,
    recurrenceDay: null as string | null,
  }));

  // ...and
  // 2.) the dry run data we get back from the API
  if (dryRunData) {
    if (dryRunData.fee.total) {
      estimatedCost = currencyFormatter.format(dryRunData.fee.total);
    }

    jobs = jobs?.map(job => {
      const storeId = job.store_location_id;
      const {
        job_start_after,
        job_finish_before,
        timezone,
        fee_per_job,
        estimated_time,
        type,
        error,
      } = dryRunData.stores[storeId];

      if (!isOfTypeDelivery && type.toLowerCase() === 'delivery') {
        isOfTypeDelivery = true;
      }

      const time_window = getFormattedDateRange(
        job_start_after,
        job_finish_before,
        timezone
      );
      const recurrenceDay =
        recurring === 'weekly' ? moment(job_start_after).format('dddd') : null;

      return {
        ...job,
        type: upperFirst(type),
        time_window,
        estimated_cost: currencyFormatter.format(fee_per_job.total),
        estimated_time,
        error,
        recurrenceDay,
      };
    });
  }

  useEffect(() => {
    if (dryRunData) {
      const number_of_jobs = Object.keys(dryRunData.stores).length;
      analytics.log('view', 'shedule_jyves_review__view', {
        number_of_jobs,
        estimated_cost: dryRunData.fee.total,
      });
    }
  }, [dryRunData]);

  return (
    <>
      <DialogTitle title="Review Jyves" onClose={onClose} />
      <MainWrapper>
        <Grid container spacing={1}>
          {isOfTypeDelivery && (
            <Grid key={`job-review-disclaimer`} item xs={12}>
              <DisclaimerBox>
                <Typography variant="caption" color="textSecondary">
                  For Delivery jobs, please check your email for details on
                  delivery time and case counts.
                </Typography>
              </DisclaimerBox>
            </Grid>
          )}
          {jobs?.map(job => {
            return (
              <Grid key={`job-review-${job.store_location_id}`} item xs={12}>
                <StoreScheduleCard {...job} dryRunErrored={dryRunErrored} />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DeliveryAndEstimatedCostWrapper>
              <EstimatedCost>
                <Typography variant="body1" align="right" noWrap>
                  {currentBrand?.public_id === 'ZC9KJ' ? 'Additional cost' : ''}
                  {currentBrand?.public_id === 'ZC9KJ' ? <sup>*</sup> : null}
                </Typography>
                <Typography variant="h4" align="right">
                  {isFetchingDryRun ? (
                    <Skeleton variant="rect" />
                  ) : (
                    estimatedCost || '—'
                  )}
                  {estimatedCost && currentBrand?.public_id !== 'ZC9KJ' ? (
                    <sup>*</sup>
                  ) : null}
                </Typography>
              </EstimatedCost>
            </DeliveryAndEstimatedCostWrapper>
          </Grid>
        </Grid>
      </MainWrapper>

      <BottomButtonsWrapper>
        {!isSubmitting && (
          <Button
            variant="contained"
            id="schedule-jobs-go-back-button"
            onClick={onGoBack}
          >
            Back
          </Button>
        )}

        <Button
          disabled={isSubmitting || isFetchingDryRun || !estimatedCost}
          type="submit"
          variant="contained"
          color="secondary"
          id="schedule-jobs-submit-button"
        >
          {isSubmitting ? (
            <CircularProgress size={20} color="inherit" disableShrink />
          ) : (
            'Confirm Jyves'
          )}
        </Button>
      </BottomButtonsWrapper>

      <BottomAsterisk>
        <Typography>
          <sup>*</sup>
          {currentBrand?.public_id === 'ZC9KJ'
            ? 'Final cost calculated after job completion'
            : 'Bolt pricing includes a convenience fee'}
        </Typography>
      </BottomAsterisk>
    </>
  );
}
