import React, { useEffect, useRef } from 'react';
import { useFormState, useForm } from 'react-final-form';
import moment, { Moment } from 'moment';

import Grid from '@material-ui/core/Grid';

import { BaseSchedulingFormValues } from 'types';

import { SeparatedDateAndTimeSelectors } from '.';

export function StartAndEndDateDeltaHandler() {
  const form = useForm();
  const { values } = useFormState<BaseSchedulingFormValues>();

  // Date change logic. In order to provide a good user experience, we want to
  // try our best to keep the start and end dates and time in sync with each other
  // NOTE: This is handled in its own component in order to have the logic run
  // even if the <CustomStartAndEndServiceWindow /> component isn't currently being
  // rendered out.
  const prevStartDateAndTime = useRef<Moment>();
  useEffect(() => {
    const { startDateAndTime, endDateAndTime } = values;
    const prevStart = prevStartDateAndTime.current;

    // If the start date or time changes, we update the end date and time with
    // the same time delta as the user had before the change. So if the range is
    // March 1st 2pm - March 2nd 4pm, the user changes the start date to be
    // March 3rd 4pm, we change the end date to be March 4th 6pm.
    //
    // Note that a special edge case here is when the end datetime has been set to
    // a datetime that's BEFORE the start datetime. If that's the case and the user
    // updates the start datetime, we leave the end datetime alone.
    if (
      !!prevStart &&
      !startDateAndTime.isSame(prevStart) &&
      !endDateAndTime.isBefore(prevStart)
    ) {
      const delta = endDateAndTime.diff(prevStart, 'hours');
      const newEnd = startDateAndTime.clone().add(delta, 'hours');
      form.change('endDateAndTime', newEnd);
    }

    if (!prevStart || !startDateAndTime.isSame(prevStart)) {
      prevStartDateAndTime.current = startDateAndTime.clone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
}

const validate = (endDateAndTime: Moment, otherValues: object) => {
  const {
    customServiceWindow,
    startDateAndTime,
  } = otherValues as BaseSchedulingFormValues;
  if (customServiceWindow && endDateAndTime.isBefore(startDateAndTime)) {
    return 'Service window must end after it starts';
  }

  if (endDateAndTime.diff(startDateAndTime, 'hours') < 1) {
    return 'Service window must be at least one hour';
  }

  return undefined;
};

export function CustomStartAndEndServiceWindow() {
  return (
    <>
      {/* START DATE AND TIME */}
      <Grid item xs={12} sm={6}>
        <SeparatedDateAndTimeSelectors
          name="startDateAndTime"
          label="Start"
          format="ddd, MMM D"
          disablePast
          maxDate={moment()
            .add(30, 'days')
            .toDate()}
          inputVariant="outlined"
          fullWidth
        />
      </Grid>

      {/* END DATE AND TIME */}
      <Grid item xs={12} sm={6}>
        <SeparatedDateAndTimeSelectors
          name="endDateAndTime"
          label="End"
          format="ddd, MMM D"
          disablePast
          maxDate={moment()
            .add(30, 'days')
            .toDate()}
          inputVariant="outlined"
          fieldProps={{
            validate,
          }}
          fullWidth
        />
      </Grid>
    </>
  );
}
