/**
 * @name JobStatusChip
 * @author Magnus <magnus@jyve.com>
 * @description
 * A chip that handles the manipulation and color coding of job statuses
 */

import React from 'react';
import styled from 'styled-components/macro';
import { upperFirst } from 'lodash';
import Chip from '@material-ui/core/Chip';

import { jobStatusMap } from 'helpers/strings';
import theme, { colors, jobStatusColors } from 'core/theme';

const JobStatusChipWrapper = styled(props => (
  <Chip
    variant={props.status === 'canceled' ? 'outlined' : 'default'}
    {...props}
  />
))<{
  status: string;
}>`
  background: ${props => jobStatusColors[props.status]};
  color: white;

  ${props =>
    props.status === 'canceled' &&
    `
    background: white;
    border-color: ${jobStatusColors.canceled}33;
    color: ${jobStatusColors.canceled};
  `}

  .MuiChip-label {
    display: flex;
    align-items: center;
  }
`;

export const InProgressDot = styled.span<{ pulse?: boolean }>`
  display: block;
  width: ${theme.spacing(1)}px;
  height: ${theme.spacing(1)}px;
  flex: 1 0 ${theme.spacing(1)}px;
  border-radius: 50%;
  background-color: ${colors.viridian};
  margin-left: ${theme.spacing(0.75)}px;

  ${props => props.pulse && 'animation: pulse 2s infinite;'}

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(59, 212, 174, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(59, 212, 174, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(59, 212, 174, 0);
    }
  }
`;

interface Props {
  status: string;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
}

export function JobStatusChip({ status, size = 'small', ...rest }: Props) {
  let label: string | JSX.Element = upperFirst(jobStatusMap[status] || status);

  if (status === 'in_progress') {
    label = (
      <>
        {label} <InProgressDot />
      </>
    );
  }

  return (
    <JobStatusChipWrapper label={label} status={status} size={size} {...rest} />
  );
}
