/**
 * @name PublicLayout
 * @description
 * The wrapper around all our public facing pages (login/register/forgot password/etc)
 */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { ReactComponent as Logo } from 'assets/logo-wordmark-with-partner-platform.svg';
import { Wrapper, Hero } from './styles';
import Footer from './Footer';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const PublicLayout = ({ children }: Props) => {
  return (
    <Wrapper>
      <Hero>
        <Box my={2}>
          <Link component={RouterLink} to="/">
            <Logo width="150" height="100%" />
          </Link>
        </Box>
      </Hero>
      <Box flex={1}>
        <Box mt="-60px" pb={4}>
          <Container maxWidth="sm">{children}</Container>
        </Box>
      </Box>
      <Footer />
    </Wrapper>
  );
};

export default PublicLayout;
