import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import uuid4 from 'uuid/v4';
import Cookies from 'js-cookie';

import { history } from 'core/routes';
import analytics from 'core/analytics';
import { CSRF_TOKEN_COOKIE_KEY } from 'core/config';

import { checkForQADebugAPIDomainOverride } from 'helpers/domainOverride';
import { API_DOMAIN, VIEWS, API } from './config';

interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  requestStartTime: number;
}

export function isAxiosError<T = any>(error: any): error is AxiosError<T> {
  return (error as AxiosError).isAxiosError !== undefined;
}

const HTTP = axios.create({
  baseURL: API_DOMAIN,
  timeout: 20000,
  withCredentials: true,
});

function getRequestDuration(config: any) {
  let result = 0;
  const requestEndTime = Date.now();
  if (config && config.requestStartTime) {
    result = requestEndTime - config.requestStartTime;
  }
  return result / 1000;
}

function trackNetworkAnalyticEvent(
  config: AxiosRequestConfig | AxiosRequestConfigExtended,
  response: any,
  error: any
) {
  const { baseURL, url, method, headers } = config;
  const requestId = headers['X-Jyve-Client-Request-ID'];
  if (response) {
    const { status } = response;
    const requestDuration = getRequestDuration(config);
    analytics.log(
      'action',
      'network_request__result',
      {
        duration: requestDuration,
        full_url: url,
        base_url: baseURL,
        status_code: status,
        is_connection_error: false,
        x_jyve_client_request_id: requestId,
        method,
      },
      true
    );
  } else if (error) {
    const requestDuration = getRequestDuration(config);
    // eslint-disable-next-line prefer-const
    let data = {
      duration: requestDuration,
      full_url: url,
      base_url: baseURL,
      status_code: null,
      is_connection_error: false,
      x_jyve_client_request_id: requestId,
      method,
    };
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      data.status_code = error.response.status;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      data.is_connection_error = true;
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
    analytics.log('action', 'network_request__result', data, true);
  }
}

HTTP.interceptors.response.use(
  response => {
    // Analytics: Network tracking
    const { config } = response;
    trackNetworkAnalyticEvent(config, response, null);
    return response;
  },
  (error: AxiosError) => {
    try {
      // Analytics: Network tracking for errors
      if (error && error.config) {
        const { config } = error;
        trackNetworkAnalyticEvent(config, null, error);
      }
    } catch (e) {} // eslint-disable-line no-empty
    return Promise.reject(error);
  }
);

// - - - - - - - - - - - - -
// JWT TOKEN REFRESH HANDLER
// TODO: Move to the bit.dev shared version of this
// - - - - - - - - - - - - -

let isAlreadyFetchingAccessToken = false;
let requestQueue: Array<() => void> = [];

const sendQueuedRequests = () => {
  requestQueue.map(cb => cb());
  requestQueue = [];
};

const refreshAccessToken = () =>
  HTTP.post(API.tokenRefresh).then(() => {
    return Promise.resolve();
  });

function createAuthRefreshInterceptor(axiosInstance: AxiosInstance): number {
  return axiosInstance.interceptors.response.use(
    // For a 200 response, we just pass the response along
    response => response,

    // If we get an error back, we parse the information and
    // handle the fetching of a new access token if needed
    (error: AxiosError) => {
      const { config, response } = error;
      const originalConfig = config;

      if (response) {
        const { status, request } = response;

        if (status === 503) {
          history.push(VIEWS.maintenance.path);
          return Promise.reject(error); // TODO: This may not be surfacing reject to original HTTP request object, essentially never completing the original Promise
        }

        /**
         * If we get a 401 or a 403 back while trying to log in, it means
         * we don't have a valid session in localStorage but cookies saved
         * by the API are still present and we have no way of knowing that
         * since they're HttpOnly. So when this happens we need to send
         * the user to the logout page to clear those cookies and then try again
         *
         * TODO: Remove this when the API has fixed this on their end
         */
        if (
          [401, 403].includes(status) &&
          request.responseURL.includes(API.login)
        ) {
          return Promise.reject(error);
        }

        // If the error status is a 401, we start collecting any
        // additional requests into a queue while we refresh
        // the access token
        if (status === 401) {
          if (request.responseURL.includes(API.tokenRefresh)) {
            // If the 401 is coming back from our token refresh endpoint, that
            // means the refresh token has expired so we log the user out
            requestQueue = [];
            const { location } = history;
            history.push(VIEWS.logout.path, {
              from: { pathname: location.pathname || VIEWS.home.path },
            });
            return Promise.reject(error); // TODO: This may not be surfacing reject to original HTTP request object, essentially never completing the original Promise
          }

          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            refreshAccessToken()
              .then(() => {
                sendQueuedRequests();
              })
              .catch(e => {
                const { location } = history;
                history.push(VIEWS.logout.path, {
                  from: { pathname: location.pathname || VIEWS.home.path },
                });
              })
              .finally(() => {
                isAlreadyFetchingAccessToken = false;
              });
          }

          const requestSubscribers = new Promise(resolve => {
            requestQueue.push(() => {
              resolve(axiosInstance(originalConfig));
            });
          });

          return requestSubscribers;
        }
      }

      return Promise.reject(error);
    }
  );
}

createAuthRefreshInterceptor(HTTP);

// - - - - - - - - - - - - -
// XHR REQUEST INTERCEPTOR
// - - - - - - - - - - - - -
HTTP.interceptors.request.use(config => {
  /**
   * If we're not on PROD, and the user has access to select their own domain,
   * this is where we override baseUrl in the cofig to make that work.
   */
  const domainOverride = checkForQADebugAPIDomainOverride();
  const baseUrl = domainOverride || config.baseURL;

  const newConfig: AxiosRequestConfigExtended = {
    ...config,
    baseURL: baseUrl,
    requestStartTime: Date.now(),
  };

  newConfig.headers['X-Jyve-Application-ID'] = 'LyveInsights';
  newConfig.headers['X-Jyve-Platform-ID'] = 'Web';
  newConfig.headers['X-Jyve-Client-Request-ID'] = uuid4();

  // Add a trailing slash to each request to fix bug in Safari
  const JUST_QUESTION = new RegExp('\\?');
  if (newConfig.url) {
    if (newConfig.url.includes('/?')) {
      // has slash in correct place before query params
      // no-op
      return newConfig;
    }
    if (newConfig.url.includes('?')) {
      // if we got here we have a first question mark with no preceding slash, so fix
      newConfig.url = newConfig.url.replace(JUST_QUESTION, '/?');
    } else if (!newConfig.url.endsWith('/')) {
      // case for no query params
      newConfig.url += '/';
    }
  }

  // Append a csrf token on POST, DELETE and PUT requests
  if (
    newConfig.method &&
    ['post', 'delete', 'put', 'patch'].includes(newConfig.method)
  ) {
    console.log('first header');
    const csrfToken = Cookies.get(CSRF_TOKEN_COOKIE_KEY);
    if (csrfToken) {
      newConfig.headers['X-CSRFToken'] = csrfToken;
      console.log(newConfig.headers['X-CSRFToken']);
      console.log('Test');
    }
  }
  console.log('I was here');

  return newConfig;
});

export default HTTP;
