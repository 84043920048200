/**
 * @name EstimatedDurationChip
 * @author Magnus <magnus@jyve.com>
 * @description
 * A chip that handles the displaying of a jobs estimated duration
 */

import React from 'react';
import { ChipProps } from '@material-ui/core/Chip';
import WatchLaterIcon from '@material-ui/icons/WatchLaterTwoTone';
import { convertMinsToHrsMins } from 'helpers/strings';

import { GreyChip } from '.';

interface Props extends ChipProps {
  estimatedTime: number;
  withoutIcon?: boolean;
}

export function EstimatedDurationChip({
  estimatedTime,
  withoutIcon = false,
  ...rest
}: Props) {
  return (
    <GreyChip
      icon={!withoutIcon ? <WatchLaterIcon /> : undefined}
      label={`~${convertMinsToHrsMins(estimatedTime)}`}
      color="default"
      size="small"
      {...rest}
    />
  );
}
