/**
 * @name useCurrentBrand
 * @description
 * A hook that grabs the current location in the URL and
 * figures out if there's an active brand in it, finds
 * that brand in the users brands object and spits
 * out and Brand.
 */
import { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from 'state/store';
import { Brand } from 'types/api';

export const getCurrentBrandID = (pathname?: string) => {
  const match = matchPath<{ brandId: string }>(
    pathname || window.location.pathname,
    {
      path: '/brands/:brandId',
      strict: false,
      exact: false,
    }
  );

  return match?.params.brandId;
};

const getBrandFromPath = (pathname: string, brands: Brand[] | null) => {
  const brandId = getCurrentBrandID(pathname);

  if (brandId) {
    const brand =
      (brands || []).filter(({ public_id: id }) => id === brandId).shift() ||
      null;

    if (brand) {
      return brand;
    }
  }

  return null;
};

export const useCurrentBrand = () => {
  const { pathname } = useLocation();
  const brands = useSelector((state: AppState) => state.user.brands);
  const [currentBrand, setCurrentBrand] = useState<Brand | null>(() => {
    const initialState = getBrandFromPath(pathname, brands);
    return initialState;
  });

  useEffect(() => {
    const matchedBrand = getBrandFromPath(pathname, brands);
    if (currentBrand !== matchedBrand) {
      setCurrentBrand(matchedBrand);
    }
  }, [pathname, brands, currentBrand]);

  return currentBrand as Brand;
};
