import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';
import { useRollbar } from '@jyveapp/react-component-library';

import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Transition } from 'dialogs/components';
import analytics from 'core/analytics';
import { useCurrentBrand } from 'helpers/hooks';
import { ScheduleJobsFormValues } from 'types';
import { isCustomDurationSelection } from 'core/analytics/helpers';
import JobsService from 'services/jobs';
import { isAxiosError } from 'core/http';

import { FormOrReview } from './components';

type Errors = {
  [P in keyof ScheduleJobsFormValues]?: string;
};

const validate = (values: ScheduleJobsFormValues) => {
  const errors: Errors = {};

  const $startDateAndTime = moment(values.startDateAndTime);
  const $endDateAndTime = moment(values.endDateAndTime);

  // At least one store is required
  if (!values.stores || values.stores.length <= 0) {
    errors.stores = 'Please select at least one store';
  }

  // Template selected
  if (!values.jobTemplateId) {
    errors.jobTemplateId = 'Please select a job type';
  }

  // Estimated job duration can't take longer than the provided service window
  let serviceWindowTimeInMinutes;
  if (!values.customServiceWindow) {
    if (values.startAndEndTimes) {
      const [startTime, endTime] = values.startAndEndTimes.split('-');
      const $startTime = moment(startTime, 'HH:mm:ss');
      const $endTime = moment(endTime, 'HH:mm:ss');
      serviceWindowTimeInMinutes = $endTime.diff($startTime, 'minutes');
    }
  } else {
    serviceWindowTimeInMinutes = $endDateAndTime.diff(
      $startDateAndTime,
      'minutes'
    );
  }

  if (
    serviceWindowTimeInMinutes &&
    Math.sign(serviceWindowTimeInMinutes) >= 0 &&
    serviceWindowTimeInMinutes < values.estimatedJobDuration
  ) {
    errors.estimatedJobDuration =
      'Job duration must be within the service window';
  }

  return errors;
};

export default function ScheduleJobsDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const rollbar = useRollbar();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const currentBrand = useCurrentBrand();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialValues = useMemo(
    () => {
      const now = moment().set({ minute: 0, second: 0, millisecond: 0 });
      const plusFiveHours = now.clone().add(5, 'hours');
      const brandTemplateConfigs = currentBrand?.self_serve?.configs;
      const initialTemplate =
        brandTemplateConfigs && brandTemplateConfigs.length > 0
          ? brandTemplateConfigs[0].job_template_id
          : undefined;

      return {
        startDateAndTime: now,
        endDateAndTime: plusFiveHours,
        jobTemplateId: initialTemplate,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Check for our route match to see if we should be open or not
  useEffect(() => {
    const matched = matchPath(location.hash, '#schedule');
    if (matched) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [location]);

  // This side-effect acts as an componentWillUnmount() to reset the state
  useEffect(() => {
    if (!isOpen) {
      setIsReviewing(false);
      setIsSubmitting(false);
    }
  }, [isOpen]);

  // If the current brand doesn't have self serve enabled
  if (!currentBrand || !currentBrand.self_serve) {
    // TODO: Figure out a more elegant way of doing this
    return <div />;
  }

  const brandPublicId = currentBrand.public_id;

  const handleClose = () => {
    history.push({
      pathname: location.pathname,
      search: location.search,
      hash: '',
    });
  };

  const handleGoBack = () => {
    setIsReviewing(false);
  };

  const onSubmit = async (values: ScheduleJobsFormValues) => {
    if (!isReviewing) {
      setIsReviewing(true);
    } else {
      setIsSubmitting(true);
      const isCustomDuration = isCustomDurationSelection(
        currentBrand,
        initialValues?.jobTemplateId || null,
        values.estimatedJobDuration
      );

      try {
        const {
          job_ids: scheduledJobIDs,
          fee,
        } = await JobsService.scheduleJobs({
          ...values,
          brandPublicId,
        });

        analytics.log('action', 'schedule_jobs_result__loaded', {
          is_successful: true,
          number_of_jobs: scheduledJobIDs.length,
          estimated_cost: fee.total,
          is_custom_duration: isCustomDuration,
          is_custom_service_window: values.customServiceWindow,
          error_msg: null,
          store_numbers:
            values.stores?.map(s => s.primary_self_identity) || null,
        });

        history.push({
          pathname: `/brands/${brandPublicId}/jobs`,
          state: { scheduledJobIDs },
        });
      } catch (error) {
        setIsSubmitting(false);

        // TODO: Use getHumanFriendlyError() when it's ready
        // Default to whatever message we get from the error instance
        let errorMessage =
          error.message ||
          'Sorry, but something seems to have gone wrong. Please try again later.';

        if (isAxiosError(error)) {
          if (error.code === 'ECONNABORTED') {
            errorMessage = 'Connection timed out. Please try again later.';
          } else if (error.response?.status === 500) {
            errorMessage =
              'Unable to communicate with server. Please try again later.';
          }
        }

        analytics.log('action', 'schedule_jobs_result__loaded', {
          is_successful: false,
          number_of_jobs: null,
          estimated_cost: null,
          is_custom_duration: isCustomDuration,
          is_custom_service_window: values.customServiceWindow,
          error_msg: errorMessage,
          store_numbers:
            values.stores?.map(s => s.primary_self_identity) || null,
        });

        try {
          const { detail } = error.response.data;
          if (detail) {
            errorMessage = `Unable to add Jyves: ${detail}`;
          }
        } catch (e) {
          rollbar.error(
            "[Schedule job]: API didn't return the correct format for an error detail!",
            { response: error.response, error, e }
          );
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
        console.error('Error while scheduling Jyves: ', error);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="schedule-jobs-dialog"
      closeAfterTransition
      fullWidth
    >
      <FinalForm<ScheduleJobsFormValues>
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormOrReview
              onClose={handleClose}
              onGoBack={handleGoBack}
              isReviewing={isReviewing}
              isSubmitting={isSubmitting}
            />
          </form>
        )}
      />
    </Dialog>
  );
}
