/**
 * This is meant to house all the helper functions around user access for brands and territories
 */
import { match as MatchType } from 'react-router-dom';
import { Location } from 'history';
import queryString from 'query-string';

import { getMomentFromOptionalDateString, getDefaultDateRange } from 'helpers';
import { InsightsFilterStructureForClient } from 'types';
import { Brand, Territory } from 'types/api';

interface CustomMatchType extends MatchType {
  params: { brandId?: string };
}

type Brands = Brand[] | null;
type Territories = Record<string, Territory> | null;

/**
 * getBrandFromUserAccess
 * Checks for a brand id within a list of the brands the user has access to
 * Returns either the brand or null
 */
export const getBrandFromUserAccess = (
  userAccessibleBrands: Brands,
  brandId: string
) => {
  if (!userAccessibleBrands) return null;
  const matchedBrandArray = userAccessibleBrands.filter(
    b => b.public_id === brandId
  );
  const matchedBrand =
    matchedBrandArray.length > 0 ? matchedBrandArray[0] : null;
  return matchedBrand;
};

/**
 * getBrandTerritoryRootNodeIDsFromUserAccess
 * Gets a list of root brand territory nodes the user has access to
 * based on the brand.
 */
export const getBrandTerritoryRootNodeIDsFromUserAccess = (
  userAccessibleBrands: Brands,
  userAccessibleTerritories: Territories,
  brandId: string
): Array<string> | null => {
  if (!userAccessibleBrands || !userAccessibleTerritories) return null;
  const brand = getBrandFromUserAccess(userAccessibleBrands, brandId);
  if (
    !brand ||
    !brand.brand_territories ||
    brand.brand_territories.length === 0
  ) {
    return null;
  }
  const territoryIDs = brand.brand_territories.filter(
    tid => !!userAccessibleTerritories[tid]
  );
  return territoryIDs;
};

/**
 * doesUserHaveAccessToTerritories
 *
 */
export const doesUserHaveAccessToTerritories = (
  userAccessibleTerritories: Territories,
  territoryIDs: string[]
) => {
  if (!userAccessibleTerritories) {
    return false;
  }
  const userHasAccessToAllIDs = territoryIDs.every(
    tid => !!userAccessibleTerritories[tid]
  );
  return userHasAccessToAllIDs;
};

/**
 * getBrandIDAndSearchString
 */
export const getBrandIDAndSearchString = (
  match: CustomMatchType,
  location: Location
): { brandId: string | null; search: string } => {
  const {
    params: { brandId },
  } = match;
  const { search } = location;
  const bid = brandId || null;
  return { brandId: bid, search };
};

/**
 * doesUserHaveAccess
 */
export const doesUserHaveAccess = (
  brandId: string | null,
  searchStr: string,
  userAccessibleBrands: Brands,
  userAccessibleTerritories: Territories
): boolean => {
  let userHasAccess = false;
  // if url references no brand, then allow
  if (!brandId) {
    userHasAccess = true;
    return userHasAccess;
  }

  try {
    if (userAccessibleBrands) {
      const brand = getBrandFromUserAccess(userAccessibleBrands, brandId);
      if (brand) {
        if (searchStr) {
          const q = queryString.parse(searchStr);
          const territories = q.territories as string;
          if (territories && territories.length > 0) {
            userHasAccess = doesUserHaveAccessToTerritories(
              userAccessibleTerritories,
              territories.split(',')
            );
          } else {
            // if we reached this point, there are no territories specified and user has access to brand
            userHasAccess = true;
          }
        } else {
          userHasAccess = true;
        }
      }
    }
  } catch (err) {
    throw new Error('doesUserHaveAccess error');
  }
  return userHasAccess;
};

/**
 * getFiltersForInsights
 */
export const getFiltersForInsights = (
  brand: Brand | null,
  searchStr: string | null
): InsightsFilterStructureForClient => {
  const brandId = brand ? brand.public_id : null;
  const defaultDateRange = getDefaultDateRange(brand?.week_starts_on || null);
  const query = searchStr ? queryString.parse(searchStr) : {};
  const { startDate, endDate, dateGrouping, ...rest } = query; // using ...rest so we can include whatever query string parameters are in the url
  const start = getMomentFromOptionalDateString((startDate as string) || null);
  const end = getMomentFromOptionalDateString((endDate as string) || null);
  const dg = dateGrouping ? (dateGrouping as string) : 'day';
  const t = query.territories;
  const territories = t && t.length > 0 ? (t as string).split(',') : null;
  const filters = {
    startDate: start || defaultDateRange.startDate,
    endDate: end || defaultDateRange.endDate,
    dateGrouping: dg,
    activeBrandId: brandId,
    selectedTerritories: territories,
    ...rest, // sending through query string params in filters
  };
  return filters;
};
