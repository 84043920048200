/**
 * @name QaDebugApiDomainSelector
 * @author Bruce MacFarlane
 * @description Allows user to select an api domain different than the default
 * for the environment.  Access to the UI for this feature is hidden behind
 * a feature flag.  When the user makes a selection, we redirect them to logout
 * and then save the cookie after logout.  For all subsequent requests, we use
 * the domain set in the cookie within our HTTP handler.  This will not work on
 * PROD environment.
 */

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import {
  API_DOMAINS,
  API_DOMAIN,
  ENV,
  ENV_PROD,
  FEATURE_FLAGS,
} from 'core/config';
import ldWrapper from 'core/ldWrapper';
import useDomainOverride from 'helpers/hooks/useDomainOverride';

const CUSTOM_API_ENTRY = 'custom-api-entry';

const StyledTextField = styled(TextField)`
  margin: 10px 0;
`;

/**
 * @name QaDebugApiDomainSelector
 * @description selector component used in menu if user has access
 */
const QaDebugApiDomainSelector = () => {
  const [domainOverrideState] = useDomainOverride();
  const insightsQaDebugEnable = ldWrapper.hasAccess(
    FEATURE_FLAGS.qaDebugEnabled
  );
  const notProd = ENV !== ENV_PROD; // we do not want to allow this selection from the production version of the web app (insights.jyve.com)
  const [redirectInfo, setRedirectInfo] = useState<{
    redirect: boolean;
    domainOverride: string | null;
  }>({
    redirect: false,
    domainOverride: null,
  });
  const [selection, setSelection] = useState<string>(
    domainOverrideState.api || API_DOMAIN
  );
  const [customUrlVal, setCustomUrlVal] = useState<string>('');

  /**
   * @name setAPIDomainOverrideAndReload
   * @description We don't actually set the value directly here which would
   * immediatley start using the new URL in requests since we still need to
   * log out with the exiting api before setting the new one.
   * This setup allows us to redirect to /logout, then logout with api before
   * setting new value.
   */
  const setAPIDomainOverrideAndReload = (value: string) => {
    setRedirectInfo({
      redirect: true,
      domainOverride: value,
    });
  };

  let content = null;
  if (redirectInfo.redirect) {
    content = (
      <Redirect
        to={{
          pathname: '/logout',
          state: { USER_SELECTED_API_DOMAIN: redirectInfo.domainOverride },
        }}
      />
    );
  } else if (insightsQaDebugEnable && notProd) {
    const knownSelection =
      Object.keys(API_DOMAINS).filter(k => API_DOMAINS[k] === selection)
        .length > 0; // if this selection is not in our config, consider it a custom URL
    content = (
      <FormControl variant="outlined" fullWidth>
        <Tooltip title={selection}>
          <InputLabel id="qa-debug-domain-selector-label">
            [QA Debug]: API Domain
          </InputLabel>
        </Tooltip>
        <Select
          labelId="qa-debug-domain-selector-label"
          id="qa-debug-domain-selector"
          value={knownSelection ? selection : CUSTOM_API_ENTRY}
          onChange={event => {
            const selectVal = (event.target.value || API_DOMAIN) as string;
            setSelection(selectVal);
            // if this is a custom url entry, do not set and refresh yet
            if (selectVal !== CUSTOM_API_ENTRY) {
              setAPIDomainOverrideAndReload(event.target.value as string);
            }
          }}
          label="[QA Debug]: API Domain"
        >
          {Object.keys(API_DOMAINS).map(k => (
            <MenuItem key={k} value={API_DOMAINS[k]}>
              {k.toUpperCase()}
            </MenuItem>
          ))}
          <MenuItem key={CUSTOM_API_ENTRY} value={CUSTOM_API_ENTRY}>
            CUSTOM API URL
          </MenuItem>
        </Select>
        {selection === CUSTOM_API_ENTRY ? (
          <>
            <StyledTextField
              id="outlined-basic"
              label="https://my-api.com/v2/"
              variant="outlined"
              onChange={event => setCustomUrlVal(event.target.value)}
            />
            <Button
              onClick={() => {
                if (customUrlVal) {
                  setAPIDomainOverrideAndReload(customUrlVal);
                }
              }}
              variant="contained"
              color="secondary"
            >
              SET CUSTOM API URL
            </Button>
          </>
        ) : null}
      </FormControl>
    );
  }

  return content;
};

export default QaDebugApiDomainSelector;
