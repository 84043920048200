/* eslint-disable no-useless-constructor */
import React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';

import { API } from 'core/config';
import HTTP from 'core/http';

export default class User {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public picture: string | null,
    public isStaff?: boolean,
    public groups?: string[]
  ) {}

  get initials() {
    return (
      this.name &&
      this.name
        .split(' ')
        .map((i: string) => i[0])
        .join('')
    );
  }

  get lastInitial() {
    return this.initials && this.initials.slice(this.initials.length - 1);
  }

  get firstName() {
    return this.name && this.name.split(' ')[0];
  }

  get lastName() {
    return this.name && this.name.split(' ').slice(-1)[0];
  }

  get imgixModifiedAvatar() {
    return `${this.picture}?fit=facearea&facepad=2&h=40&w=40`;
  }

  public avatar = (
    <MuiAvatar src={this.imgixModifiedAvatar || undefined}>
      {this.initials}
    </MuiAvatar>
  );
}

export const getUserAccessInfo = () => {
  return new Promise((resolve, reject) => {
    HTTP.get(API.myAccountInfo).then(({ data }) => resolve(data));
  });
};
