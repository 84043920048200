import { Me } from 'types/api';

/**
 * @name isMissingUserProfileInfo
 * @description
 * After login, we want to check if we are missing any pertinent
 * profile info from the user.  This a quick helper to see if any
 * of the info we want to prompt users to enter is required.
 */
export const isMissingUserProfileInfo = (userInfo: Me) => {
  const { first_name, last_name, email, profile } = userInfo;
  const isExistingWalmartEmailUser = email.endsWith('@walmart.com'); // TODO: A Walmart hack to keep existing users with access to everything from having to enter a sms_onboarding_store_location
  const {
    sms_onboarding_store_location,
    phone_number,
    sms_consent,
    title,
  } = profile;
  const res =
    !first_name ||
    !last_name ||
    email.includes('@sms.jyve.com') ||
    (!sms_onboarding_store_location && !isExistingWalmartEmailUser) ||
    !phone_number ||
    !sms_consent ||
    !title;
  return res;
};

/**
 * @name isMissingPhoneValidation
 * @description
 * After login (or after adding new phone number info), we
 * want to see if they have a phone number and it's not validated.
 */
export const isMissingPhoneValidation = (userInfo: Me) => {
  const { profile } = userInfo;
  const { phone_number_verified_at, phone_number } = profile;
  return !phone_number_verified_at && !!phone_number; // make sure we have a phone number, but no validation
};
