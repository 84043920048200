/* REDUCERS */
import { INIT_CANCEL_JOB_DIALOG, CLOSE_CANCEL_JOB_DIALOG } from './constants';
import { DialogsActions, DialogsState } from './types';

const initialState: DialogsState = {
  jobIdToCancel: null,
};

const filtersReducer = (
  state: DialogsState = initialState,
  action: DialogsActions
): DialogsState => {
  switch (action.type) {
    case INIT_CANCEL_JOB_DIALOG:
      return {
        ...state,
        jobIdToCancel: action.payload,
      };

    case CLOSE_CANCEL_JOB_DIALOG:
      return {
        ...state,
        jobIdToCancel: null,
      };

    default:
      return state;
  }
};

export default filtersReducer;
