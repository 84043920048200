import styled from 'styled-components/macro';

import theme, { colors, primaryGradient } from 'core/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh; /* needs to be min-height for iOS */
  text-align: center;
`;

export const Appbar = styled.header`
  flex: 0;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing(3)}px;
  background-color: white;
`;

export const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${primaryGradient};
  padding: ${theme.spacing(4, 2)};
  min-height: 200px;
  color: white;
`;

// TODO: Fix when styled-components fixes this: https://github.com/microsoft/TypeScript/issues/37597
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LandingPageHero = styled(Hero as any)`
  flex: 2;
  justify-content: center;
  min-height: auto;
  color: white;
`;

export const LandingPageContent = styled.main`
  flex: 2;
  background-color: white;
  padding: ${theme.spacing(4, 2, 6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.footer`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.ink};
  padding: ${theme.spacing(8, 2, 10)};
  color: white;
`;
