import React from 'react';
import styled from 'styled-components';

const CenteringWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

interface Props {
  message?: string | null;
}

const PermissionDenied = (props: Props) => (
  <CenteringWrapper>
    <p>Permission Denied</p>
    {props.message && <p>{props.message}</p>}
  </CenteringWrapper>
);

export default PermissionDenied;
