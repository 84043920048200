import React, { ReactNode, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import queryString, { ParsedQuery } from 'query-string';

/**
 * @function updateQueryString
 * @author Erdi
 * @param {Location} location
 * @param {Array<string>} remove
 * @param {Object} update
 *
 * @description
 * Remove or update any given query name from the currently loaded query parameters
 */
export const updateQueryString = (
  location: Location,
  remove?: string[],
  update?: ParsedQuery
) => {
  let queryParameters = queryString.parse(location.search);

  if (remove && remove.length > 0) {
    remove.forEach(q => delete queryParameters[q]);
  }

  if (update) {
    queryParameters = { ...queryParameters, ...update };
  }

  const qs = queryString.stringify(queryParameters);
  return `?${qs}`;
};

/**
 * @name CustomLink
 * @author Magnus
 * @description
 * A CustomLink component based on React Router's <Link />. Biggest difference
 * is that it only takes in a string for its 'to' prop (for pathname) and keeps
 * everything else that's currently in the URL intact. See examples:
 *
 * @example
 * // Update the city and country parameters and remove the state parameter
 * // in: /some/url?city=22&country=12&state=76&zip=94111
 * // out: /some/url?city=42&country=17&zip=94111
 * <CustomLink update={{city:42, country:17}} remove={['state']}>Change Query</CustomLink>
 *
 * // Only change the location of the URL but keep everything else the same
 * // in: /some/url?city=22#drawerOpen=true
 * // out: /other/url?city=22#drawerOpen=true
 * <CustomLink to="/other/url">Take me to another URL</CustomLink>
 */

interface Props {
  to?: string;
  hash?: string;
  remove?: string[];
  update?: ParsedQuery;
  children?: ReactNode;
  innerRef?: React.Ref<HTMLAnchorElement>;
  id?: string;
}

function CustomLink({
  to: pathname,
  hash,
  remove = [],
  update,
  children,
  ...rest
}: Props) {
  const urlHandler = (location: Location) => {
    const out = { ...location };

    if (remove.length > 0 || !!update) {
      out.search = updateQueryString(location, remove, update);
    }

    if (pathname) {
      out.pathname = pathname;
    }

    if (hash) {
      out.hash = hash;
    }

    return out;
  };

  return (
    <Link to={urlHandler} {...rest}>
      {children}
    </Link>
  );
}

// The use of forwardRef will no longer be required for react-router-dom v6.
// See https://github.com/ReactTraining/react-router/issues/6056
export const CustomLinkWithRef = forwardRef<HTMLAnchorElement, Props>(
  (props, ref) => <CustomLink innerRef={ref} {...props} />
);
CustomLinkWithRef.displayName = 'CustomLinkWithRef';

export default CustomLink;
