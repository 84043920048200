/**
 * Launch Darkly- ldWrapper
 *
 * LaunchDarkly has their own React implementation that wraps ldClient, but
 * it had issues and doesn't have all the features of the undlying ldClient.
 * So, made this.
 *
 * Currently we are implementing in a way where we are not listening for changes
 * made in LaunchDarkly after load.  So, to get the latest feature flag settings
 * you have to reload the browser.
 *
 * @example Initialize with either a known user or anonymous user:
 * import * as React from 'react';
 * import * as ReactDOM from 'react-dom';
 * import ldWrapper from 'core/ldWrapper';
 * import App from './App';
 * const knownUser = { key: LD_USER_KEY, email: "john@doe.com", name: "John Doe" };
 * const anonymousUser = { anonymous: true };
 * ldWrapper.init(knownUser || anonymousUser, LAUNCH_DARKLY_ID, () => {
 *    ReactDOM.render(
 *      <App />,
 *      document.getElementById('root')
 *  );
 * });
 *
 * @example Identify user after init (when user logs in):
 * ldWrapper.identify(knownUser);
 *
 * @example Reset back to anonymous user (when user logs out):
 * ldWrapper.reset();
 *
 * @example Check if user (known or anonymous) has access to a flag:
 * const hasAccess = ldWrapper.hasAccess('my-feature-flag-id-access');
 */

import * as LDClient from 'launchdarkly-js-client-sdk';
import { isEqual } from 'lodash';

interface LDUserType {
  key?: string;
  anonymous?: boolean;
  name?: string;
  email?: string;
  custom?: { [key: string]: string };
}

interface LDWrapperType {
  init: (
    userInfo: LDUserType,
    launchDarklyId: string,
    renderCallback: Function
  ) => void;
  reset: () => void;
  identify: (user: LDUserType | null) => Promise<LDUserType | null | Error>;
  hasAccess: (id: string) => boolean;
  allFlags: () => { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
  getUser: () => LDUserType | null;
}

declare global {
  interface Window {
    ldClient: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

const LD_MOCK: LDWrapperType = {
  init: () => {
    /* No Op */
  },
  reset: () => {
    /* No Op */
  },
  identify: () => {
    return Promise.resolve(null);
  },
  hasAccess: () => {
    return false;
  },
  allFlags: () => {
    return {}; /* need to return empty object to fix tests */
  },
  getUser: () => {
    return null;
  },
};

const GET_LD = () => (window && window.ldClient ? window.ldClient : LD_MOCK);

const ldWrapper = (): LDWrapperType => {
  const init = (
    userInfo: LDUserType,
    launchDarklyId: string,
    renderCallback: Function
  ) => {
    const ldClient = LDClient.initialize(
      launchDarklyId,
      userInfo || { anonymous: true },
      {
        bootstrap: 'localStorage', // this allows localStorage to be used for faster loading
      }
    );

    ldClient.on('ready', () => {
      renderCallback();
    });

    /**
     * The 'change' listener below should allow changes to the flag
     * in Launch Darkly to re-render immediatley in the browser, but has some
     * issues currently I haven't fixed yet.
     */
    ldClient.on('change', () => {
      renderCallback(); // re-render on flag changes
    });

    if (window) {
      window.ldClient = ldClient;
    }
  };

  const reset = () => {
    const ldClient = GET_LD();
    ldClient.identify({ anonymous: true });
  };

  const identify = (
    user: LDUserType | null
  ): Promise<LDUserType | null | Error> => {
    return new Promise((resolve, reject) => {
      try {
        const ldClient = GET_LD();
        const currentUser = ldClient.getUser();
        // so long as we're not trying to load the same exact user info, identify user
        if (!isEqual(currentUser, user)) {
          ldClient.identify(user || { anonymous: true }, null, () => {
            // console.log('----> LD identified:', ldClient.getUser());
            resolve(ldClient.getUser());
          });
        } else {
          resolve(ldClient.getUser());
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  const allFlags = () => {
    const ldClient = GET_LD();
    return ldClient.allFlags();
  };

  const hasAccess = (flagId: string) => {
    const ldClient = GET_LD();
    const flags = ldClient.allFlags();
    return flags[flagId] || false;
  };

  const getUser = () => {
    const ldClient = GET_LD();
    return ldClient.getUser();
  };

  return {
    init,
    reset,
    identify,
    allFlags,
    hasAccess,
    getUser,
  };
};

export default ldWrapper();
