/* REDUCERS */
import AuthService from 'services/auth';
import { UserActions, UserState } from './types';
import { GET_USER_INFO_SUCCESS } from './constants';

const initialState: UserState = {
  brands: null,
  territories: null,
  // if user is logged in already, we need to pull their latest access information
  hasOutdatedAccessInfo: AuthService.isAuthenticated(),
};

const userReducer = (
  state: UserState = initialState,
  action: UserActions
): UserState => {
  const { payload } = action;
  switch (action.type) {
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        brands: payload.insights.brands,
        territories: payload.insights.territories,
        hasOutdatedAccessInfo: false,
      };
    default:
      return state;
  }
};

export default userReducer;
