/**
 * @name useDomainOverride
 * @author Bruce
 * @description Custom hook that uses logic in domainOverride for allowing
 * a user to connect to a different api from a non-production environment.
 */

import React from 'react';
import globalHook, { Store } from 'use-global-hook';

import {
  setQADebugAPIDomainOverride,
  deleteQADebugAPIDomainOverride,
  checkForQADebugAPIDomainOverride,
} from 'helpers/domainOverride';

type DomainOverrideState = {
  api: string | null;
};

type DomainOverrideActions = {
  setApiOverride: (value: string | null) => void;
  clearApiOverride: () => void;
};

const setApiOverride = (
  store: Store<DomainOverrideState, DomainOverrideActions>,
  value: string | null
) => {
  if (value) {
    setQADebugAPIDomainOverride(value);
    const newState = { ...store.state, api: value };
    store.setState(newState);
  }
};

const clearApiOverride = (
  store: Store<DomainOverrideState, DomainOverrideActions>
) => {
  deleteQADebugAPIDomainOverride();
  const newState = { ...store.state, api: null };
  store.setState(newState);
};

const initialState: DomainOverrideState = {
  api: checkForQADebugAPIDomainOverride(),
};

const actions = {
  setApiOverride,
  clearApiOverride,
};

const useGlobalDomainOverride = globalHook<
  DomainOverrideState,
  DomainOverrideActions
>(React, initialState, actions);

export default useGlobalDomainOverride;
