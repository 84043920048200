import { combineReducers } from 'redux';

import userReducer from './user';
import filtersReducer from './filters';
import dialogsReducer from './dialogs';

const rootReducer = combineReducers({
  user: userReducer,
  filters: filtersReducer,
  dialogs: dialogsReducer,
});

export default rootReducer;
