import { Brand } from 'types/api';
import AuthService from 'services/auth';
import getDefaultBrandForUser from 'helpers/getDefaultBrandForUser';

const BRAND_ID_FROM_URL_REGEX = new RegExp('/brands/([a-zA-Z0-9]*)');

/**
 * @name getLaunchDarklyFormattedUserInfo
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description Formats user information for use with feature flagging from
 * LaunchDarkly.
 * Mostly straightforward, only tricky bit is we only allow 1 brand to be
 * associated with a user at a time for feature flagging, even though the app
 * allows a user to have many brands.  So, this function should be called when
 * loading the user in the app, as well as when the user switches to a different
 * brand.
 */
export const getLaunchDarklyFormattedUserInfo = (
  userAccessibleBrands: Brand[] | null,
  requestedUrl: string | null
) => {
  const userInfo = AuthService.getUserInfo();
  if (!userInfo) return null;

  let brandId = null; // default to no brand
  let requestedBrandIdNotFound = false; // keeps track if there is a brand ID requested that is not in their brand list

  // if url is supplied, try to pull out brand id and validate user access
  if (requestedUrl && userAccessibleBrands) {
    const match = BRAND_ID_FROM_URL_REGEX.exec(requestedUrl);
    if (match && match.length === 2) {
      const [, id] = match;
      // now make sure they have access to this brand
      const hasAccess =
        userAccessibleBrands?.filter(b => b.public_id === id).length > 0;
      if (hasAccess) {
        brandId = id;
      } else {
        requestedBrandIdNotFound = true;
      }
    }
  }

  // if we're still null, just use default brand for user
  if (brandId === null && !requestedBrandIdNotFound) {
    brandId = getDefaultBrandForUser(userAccessibleBrands);
  }

  return {
    key: userInfo.email,
    email: userInfo.email,
    name: userInfo.name,
    custom: { brand: brandId }, // In Launch Darkly, this can be used by adding a `Target users who match these rules` rule.  For the rule's attribute, select or type in 'brand', then for the value select or type in 'MY_BRAND_PUBLIC_ID'
  };
};
