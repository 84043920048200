/**
 * @name getDefaultBrandForUser
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description If the app is loaded without a specified brand, this selects
 * from either their last viewed brand (if applicable) or the first brand in
 * the list of what is accessible to them.
 */

import store from 'store2';

import { Brand } from 'types/api';
import { STORAGE_KEYS } from 'core/config';

const getDefaultBrandForUser = (userAccessibleBrands: Brand[] | null) => {
  if (!userAccessibleBrands || userAccessibleBrands.length <= 0) return null; // catch for user that does not have Insights enabled, or has not been assigned any brands yet
  // let's first take a look if the user has a previously viewed brand stored locally
  const mostRecentlyViewedBrandId = store.get(
    STORAGE_KEYS.mostRecentlyViewedBrand
  ); // we keep track of the last brand a user has viewed, so we can send them back there by default when they next load the app
  const userHasAccessToMostRecentlyViewedBrand =
    userAccessibleBrands.filter(b => b.public_id === mostRecentlyViewedBrandId)
      .length > 0; // make sure the user still has access to this brand
  const firstBrand = userAccessibleBrands[0]; // as a fallback (if needed), we can use the first brand in their list
  return mostRecentlyViewedBrandId && userHasAccessToMostRecentlyViewedBrand
    ? mostRecentlyViewedBrandId
    : firstBrand.public_id;
};

export default getDefaultBrandForUser;
