import React from 'react';
import styled from 'styled-components';

import MUIMenuItem from '@material-ui/core/MenuItem';

export const MenuItem = styled(({ innerRef, ...rest }) => (
  <MUIMenuItem {...rest} ref={innerRef} component="div" />
))`
  flex-direction: column;
  align-items: flex-start;
  justify-content: ceter;

  small {
    color: rgba(255, 255, 255, 0.5);
  }
`;
