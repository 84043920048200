/**
 * @name BannersSelector
 * @author Magnus <magnus@jyve.com>
 * @description
 * An autocomplete component that hooks into our /v2/store_chains endpoint to
 * display a single or multi selector for banners. Ready to get patched
 * into Final Form's <Field /> component.
 *
 * TODO: Create one general selector for autocomplete purposes that takes in an endpoint
 * to query for data against. This way we could combine the StoresSelector and BannersSelector
 */

import React, {
  useState,
  useEffect,
  CSSProperties,
  HTMLAttributes,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useSnackbar } from 'notistack';

import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { Omit } from '@material-ui/types';

import Select from 'react-select';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from 'react-select/src/types';
import { Option as FilterOption } from 'react-select/src/filters';

import HTTP from 'core/http';
import { useCurrentBrand } from 'helpers/hooks';
import { API } from 'core/config';
import { PaginatedApiResponse, Banner } from 'types/api';

import { MenuItem } from './styles';

const MAX_NUMBER_OF_BANNERS_ALLOWED = 10;

export interface BannerAsOption {
  value: number;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      padding: theme.spacing(1, 0.25),
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      paddingLeft: theme.spacing(1),
      minHeight: 40,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      marginLeft: theme.spacing(1.5),
    },
    placeholder: {
      position: 'absolute',
      left: theme.spacing(1.5),
      top: 15,
      color: theme.palette.text.primary,
    },
    paper: {
      position: 'absolute',
      zIndex: 100,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  })
);

function NoOptionsMessage(props: NoticeProps<BannerAsOption>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      No banners found
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;
function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<BannerAsOption>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  const { meta, ...OtherTextFieldProps } = TextFieldProps;
  const isErrored = !!(meta.touched && meta.error);

  return (
    <TextField
      error={isErrored}
      helperText={isErrored && meta.error}
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...OtherTextFieldProps}
    />
  );
}

function Option(props: OptionProps<BannerAsOption>) {
  const { label } = props;

  return (
    <MenuItem
      innerRef={props.innerRef}
      selected={props.isFocused}
      {...props.innerProps}
    >
      <main>{label}</main>
    </MenuItem>
  );
}

type MuiPlaceholderProps = Omit<
  PlaceholderProps<BannerAsOption>,
  'innerProps'
> &
  Partial<Pick<PlaceholderProps<BannerAsOption>, 'innerProps'>>;
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<BannerAsOption>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<BannerAsOption>) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props: MultiValueProps<BannerAsOption>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={props.selectProps.classes.chip}
      color="primary"
      deleteIcon={<CancelIcon {...props.removeProps} />}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Menu(props: MenuProps<BannerAsOption>) {
  const { getValue } = props;
  const numberOfSelected = (getValue() as BannerAsOption[]).length || 0;

  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {numberOfSelected >= MAX_NUMBER_OF_BANNERS_ALLOWED ? (
        <MenuItem
          disabled
        >{`Limit of ${MAX_NUMBER_OF_BANNERS_ALLOWED} banners reached`}</MenuItem>
      ) : (
        props.children
      )}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

/**
 * @name fuzzyFilterOption
 * @description
 * In order to provide our users with fuzzy search, we need to manipulate the
 * filtering options react-select uses
 */
const fuzzyFilterOption = (option: FilterOption, rawInput: string) => {
  const words = rawInput.split(' ');
  const { label } = option;
  return words.reduce(
    (acc, cur) => acc && label.toLowerCase().includes(cur.toLowerCase()),
    true
  );
};

interface Props extends FieldRenderProps<ValueType<BannerAsOption>> {
  isMulti?: boolean;
  withoutLabel?: boolean;
}

export const BannersSelector: React.FC<Props> = ({
  input,
  meta,
  isMulti = true,
  withoutLabel = false,
  ...rest
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<BannerAsOption[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const currentBrand = useCurrentBrand();
  const classes = useStyles();
  const theme = useTheme();

  const fetchAllTheBanners = () => {
    HTTP.get<PaginatedApiResponse<Banner[]>>(API.banners, {
      params: {
        store_locations__jobs__brand__public_id: currentBrand.public_id,
        page_size: 1000,
      },
    })
      .then(({ data: { results } }) => {
        const banners = results.map(banner => {
          return {
            value: banner.id,
            label: banner.name,
          };
        });

        setOptions(banners);
      })
      .catch(error => {
        enqueueSnackbar(
          `Error while fetching banners: ${error.message ||
            'Unknown error occurred'}`,
          {
            variant: 'error',
          }
        );
        console.error('Error while fetching banners: ', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(fetchAllTheBanners, []);

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    dropdownIndicator: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      cursor: 'pointer',
    }),
    clearIndicator: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      cursor: 'pointer',
    }),
    menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Select
      {...rest}
      classes={classes}
      styles={selectStyles}
      inputId="banners-selector-input"
      TextFieldProps={{
        label: !withoutLabel && (isMulti ? 'Banners' : 'Banner'),
        InputLabelProps: {
          htmlFor: 'banners-selector-input',
          shrink: true,
        },
        meta,
      }}
      placeholder={isMulti ? 'Select one or more banners' : 'Select a banner'}
      options={options}
      components={components}
      isMulti={isMulti}
      isLoading={isLoading}
      filterOption={fuzzyFilterOption}
      menuPortalTarget={document.body}
      {...input}
    />
  );
};
