/* REDUCERS */
import { SET_FILTER_SHEET_OPEN, TOGGLE_FILTER_SHEET_OPEN } from './constants';
import { FiltersActions, FiltersState } from './types';

const initialState: FiltersState = {
  isOpen: false,
};

const filtersReducer = (
  state: FiltersState = initialState,
  action: FiltersActions
): FiltersState => {
  switch (action.type) {
    case SET_FILTER_SHEET_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };

    case TOGGLE_FILTER_SHEET_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    default:
      return state;
  }
};

export default filtersReducer;
