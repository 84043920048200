import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ReactComponent as LogoWordmark } from 'assets/logo-wordmark-with-partner-platform-on-cloud.svg';
import { AppState } from 'state/store';
import { useCurrentBrand } from 'helpers/hooks';
import ldWrapper from 'core/ldWrapper';
import { getLaunchDarklyFormattedUserInfo } from 'core/ldWrapper/helpers';

import { CurrentBrandSelector } from './styles';

export default function BrandSelector() {
  const history = useHistory();
  const brands = useSelector((state: AppState) => state.user.brands);
  const currentBrand = useCurrentBrand();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // TODO: Remove hard coding
  const [subDomain] = window.location.host.split('.');
  const isOnWalmartSubdomain = subDomain === 'walmart';

  const hasMultipleBrands = Boolean(brands && brands.length > 1);

  if (isOnWalmartSubdomain || !hasMultipleBrands) {
    return (
      <Box width="100%" textAlign="center">
        <LogoWordmark />
      </Box>
    );
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const identifyUserWithNewBrandAndRedirect = async (url: string) => {
    try {
      const ldUserInfo = getLaunchDarklyFormattedUserInfo(brands, url);
      await ldWrapper.identify(ldUserInfo);
      history.push(url);
      handleClose();
    } catch (err) {
      // if there's an error, for now, let's allow the new url to go through anyways
      history.push(url);
      handleClose();
    }
  };

  return (
    <>
      <CurrentBrandSelector
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        id="main-menu-brand-selector"
      >
        <>
          {currentBrand ? currentBrand.name : 'Unknown Brand'}
          <ArrowDropDownIcon />
        </>
      </CurrentBrandSelector>

      <Menu
        id="brand-selector-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {brands &&
          brands.map(brand => (
            <MenuItem
              key={`brand-selector-${brand.public_id}`}
              onClick={() => {
                identifyUserWithNewBrandAndRedirect(
                  `/brands/${brand.public_id}`
                );
              }}
            >
              {brand.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
