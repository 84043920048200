/**
 * @name SnackbarProviderCustom
 * @description Customized SnackbarProvider from notistack with different icons
 * and styles.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';

import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import InfoIcon from '@material-ui/icons/InfoTwoTone';
import WarningIcon from '@material-ui/icons/WarningTwoTone';

import { CheckIcon } from 'assets/icons';
import theme, { colors } from 'core/theme';

const useStyles = makeStyles({
  root: {
    '& svg': {
      marginRight: `${theme.spacing(1)}px`,
    },
  },
  success: {
    backgroundColor: '#d8f6ef',
    color: colors.avocado,
  },
  error: {
    backgroundColor: '#fdd9d7',
    color: colors.pomegranate,
  },
  warning: {
    backgroundColor: '#fdd9d7',
    color: colors.pomegranate,
  },
  info: {
    backgroundColor: theme.palette.background.default,
    color: colors.grey,
  },
});

interface Props extends SnackbarProviderProps {}

const SnackbarProviderCustom: React.ComponentType<SnackbarProviderProps> = (
  props: Props
) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      iconVariant={{
        success: <CheckIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderCustom;
