/**
 * @name DateSelector
 * @author Magnus <magnus@jyve.com>
 * @description
 * A DateSelector built on material-ui's DatePicker, ready to get plugged
 * into Final Form's <Field /> component
 *
 * @todo Move this to bit.dev
 */

import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';

interface Props extends FieldRenderProps<Moment | null | undefined> {
  dateFormat?: string;
}

export const DateSelector: React.FC<Props> = ({
  input,
  meta,
  dateFormat,
  helperText,
  ...rest
}: Props) => {
  const isErrored = !!meta.error && meta.touched;
  let adjustedHelperText;
  if (isErrored) {
    adjustedHelperText = meta.error;
  } else if (helperText) {
    adjustedHelperText = helperText;
  }

  return (
    <DatePicker
      label="Date"
      fullWidth
      inputVariant="outlined"
      variant="dialog"
      format={dateFormat}
      error={isErrored}
      helperText={adjustedHelperText}
      {...rest}
      {...input}
    />
  );
};
