import { AxiosError, AxiosResponse } from 'axios';
import { UserInfo } from 'state/user/types';
import store from 'store2';

import { AccountMeResponse } from 'types/api';
import HTTP from 'core/http';
import { API, STORAGE_KEYS } from 'core/config';

interface JwtPayload {
  user_id: number;
  name: string;
  email: string;
  picture: string;
  is_staff: boolean;
  user_groups: string[];
}

const ERROR_RESPONSE = 'Unable to log in with the provided credentials.';

export const parseJwt = (token: string): JwtPayload => {
  return JSON.parse(atob(token));
};

const loginSuccess = (result: AxiosResponse<AccountMeResponse>) => {
  const { token, user } = result.data;
  if (!token || !user) {
    return Promise.reject(new Error(ERROR_RESPONSE));
  }

  // If the user is tied to the 'jyver' group, do not allow login
  // TODO: Move to CASL!
  if (user.groups.find(g => g.name === 'jyver')) {
    return Promise.reject(
      new Error('You do not have access.  Please contact support.')
    );
  }

  // If status code is 20x, store the payload portion of the
  // returned JWT in LocalStorage. We only want the payload
  // portion of the JWT, so we fetch index 1:
  const payload = token.refresh.split('.')[1];
  store.set(STORAGE_KEYS.jwtPayload, payload);

  // and return success
  return {
    userInfo: user,
  };
};

const loginFail = (err: AxiosError, errorResponse = ERROR_RESPONSE) => {
  try {
    if (err.response && err.response.status < 500) {
      return Promise.reject(new Error(errorResponse));
    }
    return Promise.reject(err);
  } catch (e) {
    return Promise.reject(err);
  }
};

export type LoginResponse = {
  userInfo: null | UserInfo;
};

export default class AuthService {
  static isAuthenticated(): boolean {
    return !!store(STORAGE_KEYS.jwtPayload);
  }

  static getUserInfo = () => {
    if (!AuthService.isAuthenticated()) {
      return null;
    }

    return parseJwt(store.get(STORAGE_KEYS.jwtPayload));
  };

  // Login with a username (email) and a password
  static login(username: string, password: string) {
    return HTTP.post<AccountMeResponse>(API.login, {
      username,
      password,
    })
      .then(loginSuccess)
      .catch(loginFail);
  }

  /**
   * @name formatMobileNumber
   * @description
   * If number comes from UI entry, +1 needs to be added before sending to server.
   * If number comes from the server, it already has the +1.
   */
  static formatMobileNumber(mobile: string) {
    return mobile.startsWith('+1') ? mobile : `+1${mobile}`;
  }

  static sendPhoneVerificationCode(mobile: string) {
    return HTTP.post(API.sendPhoneVerification, {
      mobile: AuthService.formatMobileNumber(mobile),
    });
  }

  static verifyPhoneNumberWithCode(mobile: string, code: string) {
    return HTTP.post<AccountMeResponse>(API.verifyPhone, {
      mobile: AuthService.formatMobileNumber(mobile),
      code,
    })
      .then(loginSuccess)
      .catch(error => {
        if (
          error?.response?.data?.detail?.includes(
            'Multiple user accounts found'
          )
        ) {
          return loginFail(
            error,
            'We found two users with this phone number. Please contact Jyve Support to have them merged.'
          );
        }
        return loginFail(error, 'Wrong code provided, please try again.');
      });
  }

  static async logout(): Promise<boolean> {
    try {
      // clear everything from LocalStorage
      store(false);

      // have the API clear out our HttpOnly cookies
      await HTTP.post(API.logout);

      return Promise.resolve(true);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
