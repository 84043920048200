/**
 * @name Walmart/GetStartedPage
 * @description
 * The "Get Started" page for walmart.jyve.com
 */

import React, { useState, useRef, useEffect, useContext } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { TextField, showErrorOnBlur } from 'mui-rff';
import { useHistory, Link as RouterLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';

import { isAxiosError } from 'core/http';
import { receiveLoggedInUserInfo } from 'state/user/actions';
import auth from 'services/auth';
import analytics from 'core/analytics';
import { walmartRoutes } from 'core/routes';
import { CurrentUserContext } from 'helpers/hooks';
import PublicLayout from 'layout/PublicLayout';
import { isMissingUserProfileInfo, isMissingPhoneValidation } from 'helpers';

interface FormValues {
  mobile_or_email?: string;
  password?: string;
}

type Errors = {
  [P in keyof FormValues]?: string;
};

const validate = (values: FormValues) => {
  const errors: Errors = {};

  if (!values.mobile_or_email) {
    errors.mobile_or_email =
      'Please enter a mobile phone number or an email address.';
  } else if (values.mobile_or_email?.includes('@')) {
    // User is logging in with email
    if (!values.password || values.password.trim() === '') {
      errors.password = 'Password must be provided.';
    } else if (values.password.length <= 4) {
      errors.password = 'Password must be longer than 4 characters.';
    }
  } else {
    // User is logging in with a phone number
    // eslint-disable-next-line no-lonely-if
    if (!/^\d{10,14}$/.test(values.mobile_or_email)) {
      // ^ is a really basic validation for phone number, might need to look into other options later
      errors.mobile_or_email =
        'Please enter a valid mobile phone number. The format should look like this: 5551239876';
    }
  }

  return errors;
};

export const GetStartedPage = () => {
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const mobileOrEmailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const { updateCurrentUserFromLocalStorage } = useContext(CurrentUserContext);
  const from = history.location?.state?.from || walmartRoutes.myJyves.path;

  const is_mobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  const onSubmit = async () => {
    setError(null);

    try {
      // This is a workaround for a Firefox autofill issue.  We need to read the value directly from
      // an element ref instead of using state (the 'value' prop can not be used on the TextField component)
      const mobileOrEmail = mobileOrEmailRef.current?.value || '';
      const password = passwordRef.current?.value || '';

      analytics.log('track', 'walmart_login__submit', {
        is_mobile,
        is_phone_number_login: !mobileOrEmail.includes('@'),
        brand_id: 'ZC9KJ',
        mobile_or_email: mobileOrEmail, // TODO: Not really liking that we are tracking phone numbers in segment, but that's what's happening in SMS flow currently
      });

      if (mobileOrEmail.includes('@')) {
        // We've got an email address, log the user in with their email and password
        const { userInfo } = await auth.login(mobileOrEmail, password);
        dispatch(receiveLoggedInUserInfo(userInfo));

        // identify user with email/pass login
        // if instead they are loging in with a phone number,
        // we will identify them after they enter their
        // verification code
        if (userInfo.email) {
          // we really should always have an email at this point
          analytics.identify(userInfo.email);
        }

        if (updateCurrentUserFromLocalStorage) {
          updateCurrentUserFromLocalStorage();
        }

        const missingProfileInfo = isMissingUserProfileInfo(userInfo);
        const missingPhoneValidation = isMissingPhoneValidation(userInfo);
        const phoneNumber = userInfo.profile.phone_number;

        if (missingProfileInfo) {
          /**
           * First, check if there's profile info missing.
           * If there is, route over to Complete Profile.
           * If there's action required on phone verification,
           * that page will handle it.
           */
          history.push(walmartRoutes.completeYourProfile.path, {
            from,
            userInfo,
          });
        } else if (missingPhoneValidation && phoneNumber) {
          /**
           * If we're ok on profile info, then check for
           * if phone validation is required and redirect if
           * necessary.
           */
          await auth.sendPhoneVerificationCode(phoneNumber);
          // If we're successful, we move over to verifying the code we sent to the user
          history.push(walmartRoutes.verifyPhone.path, {
            mobile: phoneNumber,
            from,
          });
        } else {
          /**
           * If all's good, route over to preload which
           * will redirect to original page request.
           */
          history.push(walmartRoutes.preload.path, {
            from,
          });
        }
      } else {
        // We've got a phone number, ask them to verify that it's in fact their number
        await auth.sendPhoneVerificationCode(mobileOrEmail);

        // If we're successful, we move over to verifying the code we sent to the user
        history.push(walmartRoutes.verifyPhone.path, {
          mobile: mobileOrEmail,
          from,
        });
      }
    } catch (err) {
      let errorMessage =
        err.message ||
        'Sorry, but something seems to have gone wrong. Please try again later.';

      if (
        isAxiosError<{
          mobile?: string[];
        }>(err)
      ) {
        if (err.response?.data.mobile?.includes('Invalid Phone Number')) {
          errorMessage = 'Please provide a valid mobile phone number.';
        }
      }

      setError(errorMessage);
    }
  };

  useEffect(() => {
    analytics.log('view', 'walmart_login__view', {
      is_mobile,
      brand_id: 'ZC9KJ',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, values, invalid, submitting }) => (
        <PublicLayout>
          <Paper>
            <Box p={2}>
              <form onSubmit={handleSubmit} noValidate>
                <Typography variant="subtitle1">
                  <strong>
                    {values.mobile_or_email?.includes('@')
                      ? 'Login'
                      : "Let's get started"}
                  </strong>
                </Typography>
                <Box my={2}>
                  <TextField
                    name="mobile_or_email"
                    label="Mobile or email"
                    autoCorrect="off"
                    autoComplete="email"
                    disabled={submitting}
                    inputRef={mobileOrEmailRef}
                    showError={showErrorOnBlur}
                    type="email"
                    required
                    autoFocus
                  />
                </Box>

                <Collapse in={values.mobile_or_email?.includes('@')}>
                  <Box mb={2}>
                    <TextField
                      name="password"
                      label="Password"
                      type="password"
                      disabled={submitting}
                      inputRef={passwordRef}
                      showError={showErrorOnBlur}
                      required
                    />
                  </Box>
                </Collapse>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={invalid || submitting}
                  startIcon={
                    submitting ? (
                      <CircularProgress
                        size={16}
                        color="inherit"
                        disableShrink
                      />
                    ) : null
                  }
                  fullWidth
                >
                  {values.mobile_or_email?.includes('@') ? 'Sign in' : 'Next'}
                </Button>
              </form>

              {!!error && (
                <Box mt={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
            </Box>
          </Paper>

          <Collapse in={values.mobile_or_email?.includes('@')}>
            <Box mt={5}>
              <Typography align="center">
                <Link
                  component={RouterLink}
                  to={walmartRoutes.forgotPass.path}
                  underline="always"
                  color="textSecondary"
                >
                  Forgot password?
                </Link>
              </Typography>
            </Box>
          </Collapse>
        </PublicLayout>
      )}
    />
  );
};
