import querystring from 'query-string';
import {
  InsightsFilterStructureForClient,
  InsightsFilterStructureForApi,
  ReportRequestQuerySet,
} from 'types';
import { REPORT_DATE_FORMAT } from 'core/config';

// TODO: Ideally api would allow a comma separated format like territory_ids=1,2
// instead of requiring a key for each value territory_ids=1&territory_ids=2
export const formatTerritoryIdsForAPI = (ids: string[]) =>
  ids.reduce((acc, id) => `${acc}&territory_ids=${id}`, '');

export const createReportParametersFromFilters = (
  filters: InsightsFilterStructureForClient
): InsightsFilterStructureForApi => {
  const {
    startDate,
    endDate,
    dateGrouping,
    activeBrandId,
    selectedTerritories,
  } = filters;
  const adjustedEndDate = endDate.clone();
  return {
    brand_public_id: activeBrandId || '',
    dates_grouping: dateGrouping,
    start_date: startDate.format(REPORT_DATE_FORMAT),
    end_date: adjustedEndDate.format(REPORT_DATE_FORMAT),
    territory_ids: selectedTerritories,
  };
};

export const insertQueryParametersToURL = (
  url: string,
  params: ReportRequestQuerySet
) => {
  const { territory_ids, ...rest } = params;
  const tStr = territory_ids ? formatTerritoryIdsForAPI(territory_ids) : '';

  const res = `${url}?${querystring.stringify(rest, {
    arrayFormat: 'comma',
    skipNull: true,
  })}${tStr}`;

  return res;
};
