import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Router } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { JyveSharedLibraryProvider } from '@jyveapp/react-component-library';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ConfirmProvider } from 'material-ui-confirm';

import { history, MainRoutes, WalmartRoutes } from 'core/routes';
import theme from 'core/theme';
import { ENV, ROLLBAR_ID, ROLLBAR_DISABLED } from 'core/config';
import ScrollToTop from 'helpers/scrollToTop';
import { HeaderTitleProvider, CurrentUserProvider } from 'helpers/hooks';
import SnackbarProviderCustom from 'components/SnackbarProviderCustom';
import HTTP from 'core/http';

// IE11 ScrollTo polyfill fix
smoothscroll.polyfill();

const rollbarConfiguration = {
  accessToken: ROLLBAR_ID,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    // We don't technically disable Rollbar, but for the purpose of being able to
    // point to stage or prod from local development without having Rollbar show
    // up in Slack, we just point it to develop here when "disabled":
    environment: ROLLBAR_DISABLED ? 'development' : ENV,
  },
};

// Grab the subdomain of the current window.location.host to direct the user
// to either our main [insights/partners].jyve.com or walmart.jyve.com
const [subDomain] = window.location.host.split('.');

const App: React.FC = () => {
  return (
    <JyveSharedLibraryProvider
      HTTP={HTTP}
      rollbarConfiguration={rollbarConfiguration}
    >
      <CurrentUserProvider>
        <HeaderTitleProvider>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProviderCustom>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ConfirmProvider>
                    {/* do not remove history, this means YOU MAGNUS!! */}
                    <Router history={history}>
                      <ScrollToTop />
                      {subDomain === 'walmart' ? (
                        <WalmartRoutes />
                      ) : (
                        <MainRoutes />
                      )}
                    </Router>
                  </ConfirmProvider>
                </MuiPickersUtilsProvider>
              </SnackbarProviderCustom>
            </ThemeProvider>
          </StylesProvider>
        </HeaderTitleProvider>
      </CurrentUserProvider>
    </JyveSharedLibraryProvider>
  );
};

export default App;
