/**
 * @name SeparatedDateAndTimeSelectors
 * @author Magnus <magnus@jyve.com>
 * @description
 * A TextField that displays a date on the left and time on the right, separated
 * by a vertical divider. The date selector opens a fullscreen date picker on mobile
 * and a dialog on desktop. The time selector is a select input with by-the-hour
 * selections.
 */

import React from 'react';
import { Moment } from 'moment';
import styled from 'styled-components/macro';
import { Field, FieldProps, FieldRenderProps, useForm } from 'react-final-form';
import { showErrorOnChange, SelectData } from 'mui-rff';

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import theme from 'core/theme';

const DateAndTimeWrapper = styled.div`
  position: relative;
  width: 100%;

  .time-selector {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: ${theme.spacing(1.5)}px;
    display: flex;
    align-items: center;
    padding: ${theme.spacing(0, 1, 0, 0)};
    cursor: default;

    .vertical-divider-wrapper {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: ${theme.spacing(1)}px;
      cursor: default;

      .MuiSelect-select.MuiSelect-select {
        background-color: rgba(0, 0, 0, 0);
        border-radius: 4px;
        padding: ${theme.spacing(0.75, 1)};

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
`;

const hoursInDay = Array.from(Array(24).keys()).map<SelectData>((e, i) => {
  const value = `0${i}`.slice(-2);
  const suffix = Number(value) >= 12 ? 'pm' : 'am';
  const label = `${((Number(value) + 11) % 12) + 1} ${suffix}`;
  return { label, value };
});

interface TimeSelectorWrapperProps
  extends FieldRenderProps<Moment, HTMLElement> {}

function TimeSelectorWrapper(props: TimeSelectorWrapperProps) {
  const form = useForm();

  const {
    input: { name, value },
  } = props;

  const handleChange = ({ target }: React.ChangeEvent<{ value: unknown }>) => {
    const { value: hourValue } = target;
    const updatedValue = value.clone().set('hours', Number(hourValue));
    form.change(name, updatedValue);
  };

  return (
    <div className="time-selector">
      <div className="vertical-divider-wrapper">
        <Select
          name={name}
          onChange={handleChange}
          value={value.format('HH')}
          input={<InputBase />}
          IconComponent={() => null}
          variant="filled"
          fullWidth={false}
        >
          {hoursInDay.map(h => (
            <MenuItem key={`hours-in-day-${h.value}`} value={h.value}>
              {h.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}

interface DatePickerWrapperProps
  extends FieldRenderProps<MuiDatePickerProps, HTMLElement> {}

function DatePickerWrapper(props: DatePickerWrapperProps) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showErrorOnChange({ meta });

  const { helperText, ...lessrest } = rest;

  return (
    <MuiDatePicker
      fullWidth
      autoOk
      helperText={isError ? error || submitError : helperText}
      error={isError}
      onChange={onChange}
      name={name}
      value={(value as any) === '' ? null : value}
      {...lessrest}
      inputProps={restInput}
    />
  );
}

export interface DateAndTimeSelectorProps
  extends Partial<Omit<MuiDatePickerProps, 'onChange'>> {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
}

export function SeparatedDateAndTimeSelectors(props: DateAndTimeSelectorProps) {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={fieldRenderProps => (
        <DateAndTimeWrapper>
          <DatePickerWrapper {...fieldRenderProps} {...rest} />
          <TimeSelectorWrapper {...fieldRenderProps} />
        </DateAndTimeWrapper>
      )}
      {...fieldProps}
    />
  );
}
