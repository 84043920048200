/**
 * @name BoltJobChip
 * @author Magnus <magnus@jyve.com>
 * @description
 * A chip that shows the bolt icon with a label
 */

import React from 'react';
import styled from 'styled-components/macro';
import Chip from '@material-ui/core/Chip';

import { BoltIcon } from 'core/styles';
import theme from 'core/theme';

const BoltJobChipWrapper = styled(props => <Chip {...props} />)`
  background: white;
  color: ${theme.palette.text.primary};
`;

interface Props {
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
}

export function BoltJobChip({ size = 'small', ...rest }: Props) {
  return (
    <BoltJobChipWrapper
      color="default"
      icon={<BoltIcon style={{ fontSize: 16 }} />}
      label="Bolt Jyve"
      size={size}
      {...rest}
    />
  );
}
