/**
 * @name StoresAsyncSearchSelector
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description
 * Stores selector component which will make an initial preload of stores the
 * user can scroll through, but also allows the user to enter search terms that
 * that are then run against the server for matching stores.  Selected values
 * are stored across any number of searches.
 *
 * TODO: This should work with FinalForm's FieldArray, but I'm currently getting a Typescript error (my guess is this is getting caused by an array mutator that is required by FinalForm messing with the typings).
 */

import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import HTTP from 'core/http';
import axios, { CancelTokenSource } from 'axios';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import { useCurrentBrand } from 'helpers/hooks';
import { API } from 'core/config';
import { PaginatedApiResponse, Store, StoreLocationAddress } from 'types/api';

const { CancelToken } = axios;

const OPTIONS_PAGE_SIZE = 50;

export interface StoreAsOption {
  value: number;
  label: string;
  address?: StoreLocationAddress;
  primary_self_identity?: string;
  chain_name?: string;
  initial_demand_source?: string;
}

interface Props extends FieldArrayRenderProps<string, never> {
  withoutLabel?: boolean;
}

export const StoresAsyncSearchSelector = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<StoreAsOption[]>([]);
  const currentBrand = useCurrentBrand();
  const { enqueueSnackbar } = useSnackbar();
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const form = useForm();
  const [defaultOptions] = useState<StoreAsOption[]>(
    form.getState().values.stores ? [...form.getState().values.stores] : []
  );
  const { withoutLabel } = props;

  /**
   * @name combineSelectionsWithCurrentOptions
   * @description
   * Since the user can make several search requests to the api endpoint for
   * different stores, loading a different set of options each time, we want to
   * include their previous selections in the current options so the
   * Autocomplete component can tell which items to highlight in the rendered
   * list(the 'getOptionSelected' function in Autocomplete will throw lots of
   * errors if these selections are not included).
   */
  const combineSelectionsWithCurrentOptions = (
    storeOptions: StoreAsOption[]
  ) => {
    let newOptions = [] as StoreAsOption[];
    const selects = form.getState().values.stores;
    if (selects) {
      newOptions = [...selects]; // since when a user searches against our server, we are clearing the existing options, we want to hold on to any previous selections that may not be in the current options load
    }
    if (storeOptions) {
      newOptions = [...newOptions, ...storeOptions];
    }
    return newOptions;
  };

  const loadOptions = (searchStr: string) => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }

    cancelToken.current = CancelToken.source();

    setIsLoading(true);

    HTTP.get<PaginatedApiResponse<Store[]>>(API.stores, {
      params: {
        brand_territory_brand_public_id: currentBrand.public_id,
        page_size: OPTIONS_PAGE_SIZE,
        ordering: 'store_chain__name,profile__primary_self_identity',
        q: searchStr,
      },
      cancelToken: cancelToken.current.token,
    })
      .then(({ data: { results } }) => {
        const stores = results.map(store => {
          return {
            value: store.id,
            label: `${store.chain_name} (${store.primary_self_identity})`,
            address: store.address,
            primary_self_identity: store.primary_self_identity,
            chain_name: store.chain_name,
          };
        });

        setOptions(combineSelectionsWithCurrentOptions(stores));
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          enqueueSnackbar(
            `Error while fetching stores: ${error.message ||
              'Unknown error occurred'}`,
            {
              variant: 'error',
            }
          );
          console.error('Error while fetching stores: ', error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const debouncedSearch = useRef(debounce((i: string) => loadOptions(i), 500))
    .current;

  useEffect(() => {
    debouncedSearch(inputValue);

    return () => {
      // cleanup
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }
    };
  }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      multiple
      id="server-side-store-search"
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.label
      }
      filterOptions={x => x}
      options={options}
      noOptionsText="no options"
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={isLoading}
      defaultValue={defaultOptions}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(event: any, selections: Array<StoreAsOption>) => {
        form.change('stores', selections);
      }}
      getOptionSelected={(option: StoreAsOption, val: StoreAsOption) => {
        return option.value === val.value;
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={withoutLabel ? null : 'Select one or more stores'}
          value=""
          placeholder="Search by store details"
          variant="outlined"
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={`store-chip-${index}`}
            label={option.label}
            {...getTagProps({ index })}
            color="primary"
          />
        ))
      }
      renderOption={option => {
        const { label, address } = option;
        const addy = address
          ? `${address.address}, ${address.city}, ${address.postal_code} ${address.state}`
          : null;
        return (
          <Grid container direction="column">
            <Grid item>{label}</Grid>
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                {addy}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default StoresAsyncSearchSelector;
