/**
 * analytics
 * Our single place for connecting with 3rd party analytics systems.
 * Initially just connecting with Segment.
 *
 * @example
 * import React from 'react';
 * import analytics from 'core/analytics';
 *
 * export default class MyComponent extends React.PureComponent {
 *   componentDidMount() {
 *     analytics.log('view', 'my_component__view', { custom_param_one: 1, custom_param_two: 2 });
 *   }
 *   render() {
 *     return <div><button onClick={() => {analytics.log('action', 'my_button__clicked')}}>My Button</button></div>;
 *   }
 * }
 */
import Smartlook from 'smartlook-client';
import { getCurrentBrandID } from 'helpers/hooks';
import { InsightsFilterStructureForClient, FiltersAnalyticType } from 'types';
import { ENV_DEV, ENV_STAGE, ENV_PROD, ENV } from '../config';

const SEGMENT_ID = process.env.REACT_APP_SEGMENT_ID || '';
const SMARTLOOK_ID = process.env.REACT_APP_SMARTLOOK_IDENTIFIER || '';

const LOG_DEV_TO_SEGMENT = true;
const LOG_EVENTS_TO_SEGMENT =
  ENV === ENV_PROD ||
  ENV === ENV_STAGE ||
  (ENV === ENV_DEV && LOG_DEV_TO_SEGMENT);

type SEGMENT_TYPE = {
  init: () => void;
  load: (id: string) => void;
  reset: () => void;
  identify: (id: string, traits?: object, options?: object) => void;
  page: (
    category: string | null,
    name: string,
    traits?: object,
    options?: object
  ) => void;
  track: (name: string, traits?: object, options?: object) => void;
  user: () => any;
};

const SEGMENT_MOCK: SEGMENT_TYPE = {
  init: () => {
    /* No Op */
  },
  load: () => {
    /* No Op */
  },
  reset: () => {
    /* No Op */
  },
  identify: () => {
    /* No Op */
  },
  page: () => {
    /* No Op */
  },
  track: () => {
    /* No Op */
  },
  user: () => {
    return {};
  },
};
declare global {
  interface Window {
    analytics: any;
  }
}
const GET_SEGMENT = (): SEGMENT_TYPE =>
  window && window.analytics ? window.analytics : SEGMENT_MOCK;
const GET_SMARTLOOK = () => Smartlook;

const LOG_TO_CONSOLE =
  (ENV === ENV_DEV || ENV === ENV_STAGE) && GET_SEGMENT() !== SEGMENT_MOCK;

if (LOG_TO_CONSOLE && (ENV === ENV_DEV || ENV === ENV_STAGE)) {
  console.debug('LOG_EVENTS_TO_SEGMENT:', LOG_EVENTS_TO_SEGMENT); // eslint-disable-line no-console
}

const analytics = () => {
  const init = () => {
    if (LOG_EVENTS_TO_SEGMENT) {
      GET_SEGMENT().load(SEGMENT_ID);
      GET_SMARTLOOK().init(SMARTLOOK_ID);
    }

    if (LOG_TO_CONSOLE) {
      console.debug('[analytics]: init'); // eslint-disable-line no-console
    }
  };

  const reset = () => {
    if (LOG_EVENTS_TO_SEGMENT) {
      GET_SEGMENT().reset();
    }

    if (LOG_TO_CONSOLE) {
      console.debug('[analytics]: reset'); // eslint-disable-line no-console
    }
  };

  /**
   * @name getDefaultOptions
   * @author Bruce <bruce.macfarlane@jyve.com>
   * @description Currently only using this to not allow events from Jyve
   * internal users to go to Google Analytics using the 'integrations' object.
   * This could be used to apply any other Segment options we want as well.
   */
  const getDefaultOptions = () => {
    try {
      const userId = GET_SEGMENT().user().id();
      if (userId && userId.indexOf('@jyve.com') >= 0) {
        return {
          integrations: {
            All: true,
            'Google Analytics': false,
          },
        };
      }
    } catch (err) {
      // no op
    }
    return {}; // fallback
  };

  const identify = (email: string) => {
    const traits = {};
    const defaultOptions = getDefaultOptions();
    if (LOG_EVENTS_TO_SEGMENT) {
      GET_SEGMENT().identify(email, traits, defaultOptions);
      GET_SMARTLOOK().identify(email, traits);
    }

    if (LOG_TO_CONSOLE) {
      console.debug('[analytics]: identify', email, traits); // eslint-disable-line no-console
    }
  };

  const log = (
    type: string,
    name: string,
    properties: any = null,
    withoutBrandId = false
  ) => {
    const newProps = { ...properties };
    const defaultOptions = getDefaultOptions();

    if (!withoutBrandId && !newProps.brand_id) {
      newProps.brand_id = getCurrentBrandID();
    }

    if (LOG_EVENTS_TO_SEGMENT) {
      if (type.toLowerCase() === 'view') {
        GET_SEGMENT().page(null, name, newProps, defaultOptions);
      } else {
        GET_SEGMENT().track(name, newProps, defaultOptions);
        GET_SMARTLOOK().track(name, newProps);
      }
    }

    if (LOG_TO_CONSOLE) {
      console.debug('[analytics]: log', type, name, newProps); // eslint-disable-line no-console
    }
  };

  return {
    init,
    reset,
    identify,
    log,
  };
};

export function formatFiltersForAnalytics(
  filters: InsightsFilterStructureForClient
): FiltersAnalyticType {
  const startDateStr = filters.startDate.toISOString();
  const endDateStr = filters.endDate.toISOString();
  return {
    ...filters,
    startDate: startDateStr,
    endDate: endDateStr,
  };
}

export default analytics();
