import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import theme from 'core/theme';

interface Props {
  fullPage?: boolean;
}

const SpinnerWrapper = styled.aside<Props>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: ${theme.spacing(2, 0)};

  ${props =>
    props.fullPage &&
    `
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  `}
`;

export default function Loader({ fullPage = false }: Props) {
  return (
    <SpinnerWrapper fullPage={fullPage}>
      <CircularProgress color="primary" />
    </SpinnerWrapper>
  );
}
