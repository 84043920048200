/* ACTIONS */
import { SET_FILTER_SHEET_OPEN, TOGGLE_FILTER_SHEET_OPEN } from './constants';

export const setFilterSheetOpen = (open: boolean) => ({
  type: SET_FILTER_SHEET_OPEN,
  payload: open,
});

export const toggleFilterSheetOpen = () => ({
  type: TOGGLE_FILTER_SHEET_OPEN,
});
