/**
 * @name convertMinsToHrsMins
 * @author Magnus <magnus@jyve.com>
 * @description
 * Given minutes, returns hours and minutes
 *
 * @param {number} mins Number of minutes
 * @param {boolean} roundToHour (default: false)
 * @param {boolean} abbreviateSuffix (default: false)
 * @returns {string}
 *
 * @example
 * // returns '1 hour and 22 minutes'
 * convertMinsToHrsMins(82)
 */
export default function convertMinsToHrsMins(
  mins: number,
  roundToHour = false,
  abbreviateSuffix = true
) {
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  let hPhrase = h === 1 ? ' hour' : ' hours';
  let mPhrase = m === 1 ? ' minute' : ' minutes';

  if (abbreviateSuffix) {
    hPhrase = h === 1 ? ' hr' : ' hrs';
    mPhrase = ' min';
  }

  if (h <= 0) {
    return `${m}${mPhrase}`;
  }

  if (h >= 1 && m <= 0) {
    return `${h}${hPhrase}`;
  }

  if (h >= 1 && roundToHour) {
    const roundedH = Math.round(mins / 60) % 24;
    return `~${roundedH}${hPhrase}`;
  }

  return `${h}${hPhrase}${abbreviateSuffix ? ' ' : ' and '}${m}${mPhrase}`;
}
