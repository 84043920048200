/**
 * @name StoreSearchAsyncByPrimaryIdTextField
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description
 * Async search field that looks up a store by it's primary identifier.
 * Works inside Final Form.  Only works for an authenticated user.
 * The main bit here is we are using the async 'validate' function from <Field />
 * To check our server for a matching store against current brand and primary identifier entered by the user.
 * Example:
 * <StoreSearchAsyncByPrimaryIdTextField
    label="Store number *"
    variant="outlined"
    fullWidth
    name="storeNumber"
    onStoreValidationChange={({status, validatedStoreId, validatedStore}) => {
      console.log('status:', status, validatedStoreId, validatedStore);
    }}
  />
 * FinalForm async validation ref: https://codesandbox.io/s/wy7z7q5zx5
*/

import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import axios, { CancelTokenSource } from 'axios';
import styled from 'styled-components/macro';
import pDebounce from 'p-debounce'; // TODO: There's gotta be some way of getting _.debounce to work for this

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import MuiBox from '@material-ui/core/Box';

import HTTP from 'core/http';
import { useCurrentBrand } from 'helpers/hooks';
import { API } from 'core/config';
import { colors } from 'core/theme';
import { PaginatedApiResponse, Store } from 'types/api';
import simpleMemoize from 'helpers/simpleMemoize';

const { CancelToken } = axios;

const Box = styled(MuiBox)`
  background-color: ${colors.cloud};
  border-radius: 3px;
`;

const FeedbackMessage = (props: {
  title: string;
  message: string;
  isError?: boolean;
}) => {
  return (
    <Box p={2} mt={2} textAlign="left">
      <Typography
        variant="body1"
        style={{ fontWeight: 600 }}
        color={props.isError ? 'secondary' : 'primary'}
      >
        {props.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {props.message}
      </Typography>
    </Box>
  );
};

type StatusOption =
  | 'awaiting-entry'
  | 'pending'
  | 'success'
  | 'notfound'
  | 'error';

type StatusChangePayload = {
  status: StatusOption;
  validatedStoreId: string | null;
  userEnteredStr: string;
  validatedStore: Store | null;
};

type Props = TextFieldProps & {
  name: string; // field name for Final Form
  validatedFieldName?: string | null; // field name of the validated store ID
  onStoreValidationChange?: (payload: StatusChangePayload) => void; // optional, if working inside Final Form, it's not needed
};

const StoreSearchAsyncByPrimaryIdTextField = (props: Props) => {
  const form = useForm();
  const [status, setStatus] = useState<StatusOption>('awaiting-entry');
  const [storeNumberEntry, setStoreNumberEntry] = useState('');
  const [store, setStore] = useState<Store | null>(null);
  const [loadingError, setLoadingError] = useState<Error | null>(null);
  const currentBrand = useCurrentBrand();
  const cancelToken = useRef<CancelTokenSource | null>(null);

  const searchForStore = async (searchStr: string) => {
    try {
      if (cancelToken.current) {
        cancelToken.current.cancel(); // cancel any active requests
      }
      cancelToken.current = CancelToken.source();
      setStore(null);
      setLoadingError(null);
      setStoreNumberEntry(searchStr);
      if (!searchStr) {
        setStatus('awaiting-entry');
        return 'Please enter your store number'; // if we are in the loading state, we want the form to know we are still invalid
      }
      const {
        data: { results },
      } = await HTTP.get<PaginatedApiResponse<Store[]>>(`${API.stores}`, {
        params: {
          brand_territory_brand_public_id:
            (currentBrand && currentBrand.public_id) || 'ZC9KJ', // TODO: hardcoding Walmart as fallback for now since we're not in normal path.  Ideally we wouldn't need the fallback.
          primary_self_identity: searchStr,
        },
        cancelToken: cancelToken.current.token,
      });
      const storeMatch = results
        .filter(s => s.primary_self_identity === searchStr)
        .shift();
      if (storeMatch) {
        setStore(storeMatch);
        setStatus('success');
      } else {
        setStatus('notfound');
      }
      return storeMatch ? '' : 'Please enter a valid store ID.'; // resolve with message if store is not found to let final form know we are invalid
    } catch (error) {
      if (!axios.isCancel(error)) {
        setLoadingError(error);
        setStatus('error');
        return `Error: ${error.message}`;
      }
      return '';
    }
  };

  const debouncedSearch = useCallback(
    simpleMemoize(pDebounce(searchForStore, 500)),
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @name handleAsyncValidationRequest
   * @description
   * Allows us to run an async request to see if we find a valid store
   * from the user supplied string and set field validation accordingly.
   * Since this function can get called when other fields in a form are
   * updated (depending on how subscriptions are set up in FinalForm), we
   * need to check if our field's value has changed or not before perfoming
   * any actions.
   * Here, if value has not changed, we keep from going into a 'pending' state.
   * The 'searchForStore' debounced function is also memoized so keep the same
   * input from running the async request again.
   */
  const handleAsyncValidationRequest = (input: string) => {
    // this function can get called when other fields in a form are updated (depending on how subscriptions are set up in FinalForm).
    const inputHasChanged = input !== storeNumberEntry;
    if (input && status !== 'pending' && inputHasChanged) {
      setStore(null); // clear out store if we currently have one
      setStatus('pending'); // we want to set to pending before debounce timeout completes
    }
    return debouncedSearch(input);
  };

  let storeDetails = null;
  if (status === 'error' && loadingError) {
    storeDetails = ( // could move this to a snackbar message, but figured we had the real estate here anyways
      <FeedbackMessage
        title="Loading error"
        message={loadingError.message}
        isError
      />
    );
  } else if (status === 'success' && store) {
    const { chain_name, primary_self_identity, address } = store;
    const addy = address
      ? `${address.address}, ${address.city}, ${address.postal_code} ${address.state}`
      : '';
    storeDetails = (
      <FeedbackMessage
        title={`${chain_name} (${primary_self_identity})`}
        message={addy}
      />
    );
  } else if (status === 'notfound') {
    // no store matches existing search term
    storeDetails = (
      <FeedbackMessage
        title="Store not found!"
        message={`We did not find store "${storeNumberEntry}" in our records. Please try again or contact Jyve Support.`}
        isError
      />
    );
  }

  const {
    name,
    validatedFieldName,
    onStoreValidationChange,
    ...textFieldProps
  } = props;

  useEffect(() => {
    if (validatedFieldName) {
      form.change(
        validatedFieldName,
        status === 'success' && !!store ? store.id : null
      );
    }

    if (onStoreValidationChange) {
      const payload: StatusChangePayload = {
        status,
        validatedStoreId: status === 'success' ? storeNumberEntry : null,
        userEnteredStr: storeNumberEntry,
        validatedStore: store,
      };
      onStoreValidationChange(payload);
    }
    // within the useEffect hook, we only want to trigger changes off status changes, not storeNumber changes
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Field name={name} validate={handleAsyncValidationRequest}>
        {({ input, meta }) => (
          <div>
            <TextField
              {...textFieldProps}
              {...input}
              inputProps={{ maxLength: 16 }}
              InputProps={{
                endAdornment: meta.validating ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} style={{ marginRight: 14 }} />
                  </InputAdornment>
                ) : null,
              }}
              error={meta.touched && (!!meta.error || status === 'pending')}
              helperText={
                !input.value && meta.touched && !!meta.error
                  ? meta.error
                  : undefined
              }
            />
          </div>
        )}
      </Field>
      <Collapse in={!!storeDetails}>{storeDetails}</Collapse>
    </>
  );
};

export default StoreSearchAsyncByPrimaryIdTextField;
