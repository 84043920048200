import {
  ENV,
  SENTRY_DSN,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  SENTRY_TRACE_PROPAGATION_TARGETS,
  SENTRY_TRACE_SAMPLE_RATE,
} from 'core/config';
import { history } from 'core/routes';
import { matchPath } from 'react-router-dom';

import * as Sentry from '@sentry/react';

try {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    integrations: [
      // REACT ROUTER INTEGRATION
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        matchPath,
      }),

      // SESSION REPLAY INTEGRATION
      Sentry.replayIntegration(),

      // BROWSER TRACING
      Sentry.browserTracingIntegration(),
    ],

    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    tracePropagationTargets: SENTRY_TRACE_PROPAGATION_TARGETS,

    replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  });

  // eslint-disable-next-line no-console
  console.log('SENTRY: initialization successful');
} catch (error) {
  // eslint-disable-next-line no-console
  console.error('SENTRY: initialization failed', error);
}
