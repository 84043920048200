/**
 * @name GreyChip
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description Styled Material UI Chip component to match design since it
 * didn't seem easy to do directly on Mui Chip itself.  Is there better way?
 */

import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Chip, { ChipProps } from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    greyChip: {
      backgroundColor: theme.palette.background.default,
      '& .MuiChip-icon, & .MuiChip-label': {
        color: theme.palette.text.secondary,
        fontWeight: 'normal',
      },
    },
  })
);

export function GreyChip(props: ChipProps) {
  const classes = useStyles();
  return <Chip className={classes.greyChip} {...props} />;
}
