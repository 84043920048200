/**
 * @name useCurrentUser
 * @author Magnus <magnus@jyve.com>
 * @description
 * A hook that provides a basic api for accessing information
 * on the currently logged in user
 */
import React, { useState, createContext, useContext, ReactNode } from 'react';
import store from 'store2';

import { STORAGE_KEYS } from 'core/config';
import { parseJwt } from 'services/auth';
import User from 'services/user';

interface ContextProps {
  user: User | null;
  updateCurrentUserFromLocalStorage: () => void;
}

export const CurrentUserContext = createContext<Partial<ContextProps>>({});

interface ProviderProps {
  children?: ReactNode;
}

const getUserObject = () => {
  const jwtPayload = store.get(STORAGE_KEYS.jwtPayload);
  if (jwtPayload) {
    const u = parseJwt(jwtPayload);
    return new User(
      u.user_id,
      u.name,
      u.email,
      u.picture,
      u.is_staff,
      u.user_groups
    );
  }

  return null;
};

export const CurrentUserProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<User | null>(() => getUserObject());

  const updateCurrentUserFromLocalStorage = () => {
    setUser(getUserObject());
  };

  return (
    <CurrentUserContext.Provider
      value={{ user, updateCurrentUserFromLocalStorage }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const { user } = useContext(CurrentUserContext);
  return user;
};
