/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import MuiCard from '@material-ui/core/Card';

import theme, { colors } from 'core/theme';

export const Card = styled(({ errored, ...rest }) => (
  <MuiCard {...rest} elevation={3} />
))<{
  errored: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${props =>
      props.errored
        ? `${theme.palette.error.main}ff`
        : `${theme.palette.error.main}00`};
  transition: border 400ms;

  hr {
    margin: ${theme.spacing(1)}px 0px;
  }

  .chips {
    margin-top: ${theme.spacing(1)}px;

    .MuiChip-root {
      margin-right: ${theme.spacing(1)}px;
    }
  }

  .MuiSkeleton-root {
    display: inline-flex;
    margin-right: ${theme.spacing(1)}px;
  }

  .MuiCardContent-root {
    filter: ${props =>
      props.errored
        ? 'opacity(50%) grayscale(100%)'
        : 'opacity(100%) grayscale(0%)'};
    transition: filter 400ms;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ErrorBanner = styled.div`
  color: white;
  padding: ${theme.spacing(1)}px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${theme.palette.error.main};

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing(1)}px;
    }
  }
`;
