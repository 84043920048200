import styled from 'styled-components/macro';

import theme, { colors, primaryGradient } from 'core/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh; /* needs to be min-height for iOS */
  text-align: center;
`;

export const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${primaryGradient};
  padding: ${theme.spacing(4, 2)};
  min-height: 200px;
  color: white;
`;

export const Footer = styled.footer`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.ink};
  padding: ${theme.spacing(8, 2, 10)};
  color: white;
`;
