/**
 * @name Walmart/CompleteYourProfile
 * @description
 * When we get new users on walmart.jyve.com or we have a user come back
 * without having finished their sign-up process the last time, they end
 * up here. We ask for their store number (the only required field),
 * first/last name, email, phone number, title and password.
 */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { TextField, Select } from 'mui-rff';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';

import { walmartRoutes } from 'core/routes';
import http from 'core/http';
import analytics from 'core/analytics';
import { API } from 'core/config';
import { Me } from 'types/api';
import auth from 'services/auth';
import { receiveLoggedInUserInfo } from 'state/user/actions';
import PublicLayout from 'layout/PublicLayout';

import StoreSearchAsyncByPrimaryIdTextField from '../components/form-components/StoreSearchAsyncByPrimaryIdTextField';

interface FormValues {
  store_number?: string; // the number entered by the user
  sms_onboarding_store_location?: string; // the store id sent over to the api
  first_name?: string;
  last_name?: string;
  title?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  sms_consent?: boolean;
}

type Errors = {
  [P in keyof FormValues]?: string;
};

const validate = (values: FormValues) => {
  const errors: Errors = {};

  if (
    'sms_onboarding_store_location' in values &&
    values.sms_onboarding_store_location === null
  ) {
    // This is technically never shown to the user since the StoreSearchAsyncByPrimaryIdTextField
    // handles its own error displaying, but it is important for the Submit button to be disabled
    // until a valid store has been entered.
    errors.store_number = 'Please enter a valid store number.';
  }

  if (values.email && values.email.length > 0) {
    if (!values.password || values.password.trim() === '') {
      errors.password = 'Please provide a password.';
    } else if (values.password.length < 8) {
      errors.password = 'Must contain at least 8 characters.';
    }

    if (!values.confirm_password || values.confirm_password.trim() === '') {
      errors.confirm_password =
        'Please re-enter your password for confirmation.';
    } else if (values.confirm_password.length < 8) {
      errors.confirm_password = 'Must contain at least 8 characters.';
    }

    if (values.password !== values.confirm_password) {
      errors.confirm_password = 'Passwords need to match. Please try again.';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = 'Please enter a valid email address.';
    }
  }

  if (values.phone_number && !/^\d{10,14}$/.test(values.phone_number)) {
    errors.phone_number =
      'Please enter a valid mobile phone number. The format should look like this: 5551239876';
  }

  return errors;
};

export const CompleteYourProfilePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [missingInfo, setMissingInfo] = useState<Array<keyof FormValues>>([]);
  const [error, setError] = useState<string | null>(null);
  const from = history.location?.state?.from || walmartRoutes.myJyves.path;
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  // Check if there's user info missing that we want to collect. If there
  // is nothing missing we send the user on their way into JPP
  useEffect(() => {
    if (!auth.isAuthenticated()) {
      history.replace(walmartRoutes.home.path);
      return;
    }

    const userInfo = (history.location?.state?.userInfo as Me) || {};
    const isExistingWalmartEmailUser = userInfo.email.endsWith('@walmart.com');
    const missing: Array<keyof FormValues> = [];

    if (
      !userInfo.profile.sms_onboarding_store_location &&
      !isExistingWalmartEmailUser
    ) {
      // if they are already signed up with an email/password that is from Walmart.com, likely they have whatever brand territories they need
      // TODO: This 'isExistingWalmartEmailUser' may be the wrong approach as we're assuming the user has had all appropriate access set
      // when they're email/password account was created, which may definitely not be the case.  Issue has been raised though.
      missing.push('sms_onboarding_store_location');
    }
    if (!userInfo.profile.phone_number) {
      missing.push('phone_number');
    }
    if (!userInfo.profile.sms_consent) {
      missing.push('sms_consent');
    }
    if (!userInfo.first_name || userInfo.first_name.length <= 0) {
      missing.push('first_name');
    }
    if (!userInfo.last_name || userInfo.last_name.length <= 0) {
      missing.push('last_name');
    }
    if (!userInfo.profile.title) {
      missing.push('title');
    }
    if (
      !userInfo.email ||
      userInfo.email.length <= 0 ||
      userInfo.email.startsWith('sms+')
    ) {
      missing.push('email');
    }

    setMissingInfo(missing);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Analytics
  useEffect(() => {
    analytics.log('view', 'walmart_complete_profile__view', {
      is_mobile: isMobile,
      brand_id: 'ZC9KJ',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (values: FormValues) => {
    setError(null);

    try {
      /* BEGIN: Compile form values into payload */
      const {
        first_name,
        last_name,
        email,
        sms_onboarding_store_location,
        title,
        phone_number,
        password,
        sms_consent,
      } = values;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload: { [key: string]: any } = {
        profile: {},
      };
      if (first_name) payload.first_name = first_name;
      if (last_name) payload.last_name = last_name;
      if (email) payload.email = email;
      if (password) payload.password = password;
      if (sms_onboarding_store_location)
        payload.profile.sms_onboarding_store_location = sms_onboarding_store_location;
      if (title) payload.profile.title = title;
      if (phone_number) payload.profile.phone_number = phone_number;
      if (sms_consent)
        payload.profile.sms_consent_updated_at = moment().toISOString(); // endpoint expects sms_consent_updated_at instead of sms_consent, and it's value must be an ISO string
      /* END Compile form values into payload */

      const response = await http.post(API.accountMe, payload);

      analytics.log('track', 'walmart_complete_profile__submit', {
        is_mobile: isMobile,
        brand_id: 'ZC9KJ',
        isSkipped: false,
        error_msg: null,
        first_name: first_name || null, // TODO: Uncomforatable with sending this info to Segment
        last_name: last_name || null, // TODO: Uncomforatable with sending this info to Segment
        email: email || null, // TODO: Uncomforatable with sending this info to Segment
        sms_onboarding_store_location: sms_onboarding_store_location || null,
        title: title || null,
        phone_number: phone_number || null, // TODO: Uncomforatable with sending this info to Segment
        sms_consent: sms_consent || null,
      });

      const verifyPhone =
        response.data?.profile?.phone_number_verified_at === null;
      const phoneNumber =
        values.phone_number || response.data?.phone_number || null;

      if (missingInfo.includes('sms_onboarding_store_location')) {
        // Since we just updated the users store id, they now have an updated list of
        // brand territories. So here we need to refetch the users info
        const { data } = await http.get<Me>(API.myAccountInfo);
        dispatch(receiveLoggedInUserInfo(data));
      }

      if (verifyPhone && phoneNumber) {
        // send verification code
        await auth.sendPhoneVerificationCode(phoneNumber);
        // If we're successful, we move over to verifying the code we sent to the user
        history.push(walmartRoutes.verifyPhone.path, {
          mobile: phoneNumber,
          from,
          skipCompleteProfile: true, // user has already been taken through complete profile
        });
      } else {
        history.push(walmartRoutes.preload.path, {
          from,
        });
      }
      return {}; // need consistent return since this is how submitErrors are set
    } catch (err) {
      analytics.log('track', 'walmart_complete_profile_submit', {
        is_mobile: isMobile,
        brand_id: 'ZC9KJ',
        isSkipped: false,
        error_msg: err.message,
      });

      const isMalformedRequest =
        err.response?.data?.error_code === 'malformed_request';
      // If error comes from the user entering an email or phone number
      // that is already in use, we need to set submitErrors on the form.
      const emailOrPhoneAlreadyExists =
        err.response?.data?.error_code === 'already_exists';
      // handle error message to user
      let errorMsg = err.message || 'Unknown error occurred'; // default
      if (isMalformedRequest && err.response?.data?.detail) {
        errorMsg = err.response?.data?.detail;
      } else if (emailOrPhoneAlreadyExists) {
        errorMsg = 'Please fix errors above.'; // this error_code doesn't include 'detail' in the response
      }

      setError(errorMsg);
      if (emailOrPhoneAlreadyExists) {
        const isEmail = !!values.email;
        if (isEmail) {
          return { email: 'Email address is already taken.' };
        }
        return { phone_number: 'Phone number is already taken.' };
      }
      return {}; // need consistent return since this is how submitErrors are set
    }
  };

  const onSkip = (event: React.SyntheticEvent) => {
    event.preventDefault();
    analytics.log('track', 'walmart_complete_profile_submit', {
      is_mobile: isMobile,
      brand_id: 'ZC9KJ',
      isSkipped: true,
      error_msg: null,
    });
    // check if we need to validate a phone number we have onfile with user
    const userInfo = (history.location?.state?.userInfo as Me) || {};
    const phone_number_verified_at = userInfo.profile?.phone_number_verified_at;
    const phone_number = userInfo.profile?.phone_number;
    if (phone_number && !phone_number_verified_at) {
      // send verification code
      auth
        .sendPhoneVerificationCode(phone_number)
        .then(() => {
          // If we're successful, we move over to verifying the code we sent to the user
          history.push(walmartRoutes.verifyPhone.path, {
            mobile: phone_number,
            from,
            skipCompleteProfile: true, // user has already been taken through complete profile
          });
        })
        .catch(err => {
          // making this a no-op with a redirect since if there's an error at this point it's not
          // critical enough to keep user from being logged in and there's nothing else the user could do
          history.push(walmartRoutes.preload.path, { from });
        });
    } else {
      history.push(walmartRoutes.preload.path, { from });
    }
  };

  return (
    <PublicLayout>
      {missingInfo.length <= 0 ? (
        <Box color="white">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Paper>
          <Box py={2} px={2}>
            <Form<FormValues>
              onSubmit={onSubmit}
              validate={validate}
              render={({
                handleSubmit,
                valid,
                submitting,
                values,
                hasSubmitErrors,
                form,
                submitErrors,
              }) => {
                /**
                 * @name formIsValid
                 * @description
                 * annoyingly Final Form does not handle submitErrors well, so we not only have to check
                 * for the form being valid, but we need to also check for hasSubmitErrors and see if
                 * each of the submit error fields is dirty since last submit to properly show submit button.
                 * See Github issue: https://github.com/final-form/react-final-form/issues/403
                 */
                const formIsValid =
                  valid ||
                  (hasSubmitErrors &&
                    Object.keys(submitErrors).every(key => {
                      const f = form.getFieldState(key as keyof FormValues);
                      return f && f.dirtySinceLastSubmit;
                    }));
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Box mb={3}>
                      <Typography variant="subtitle1">
                        <strong>Complete your profile</strong>
                      </Typography>

                      <Typography color="textSecondary">
                        We need some additional information to finish setting up
                        your account
                      </Typography>
                    </Box>

                    {missingInfo.includes('sms_onboarding_store_location') && (
                      <Box my={2}>
                        <StoreSearchAsyncByPrimaryIdTextField
                          name="store_number"
                          validatedFieldName="sms_onboarding_store_location"
                          label="Store number"
                          disabled={submitting}
                          fullWidth
                          required
                        />
                      </Box>
                    )}

                    {missingInfo.includes('first_name') && (
                      <Box my={2}>
                        <TextField
                          name="first_name"
                          label="First name"
                          inputProps={{
                            maxLength: 127,
                          }}
                          disabled={submitting}
                        />
                      </Box>
                    )}

                    {missingInfo.includes('last_name') && (
                      <Box my={2}>
                        <TextField
                          name="last_name"
                          label="Last name"
                          inputProps={{
                            maxLength: 127,
                          }}
                          disabled={submitting}
                        />
                      </Box>
                    )}

                    {missingInfo.includes('title') && (
                      <Box my={2} textAlign="left">
                        <Select
                          name="title"
                          label="Title"
                          disabled={submitting}
                          variant="outlined"
                        >
                          <MenuItem value="Store Manager">
                            Store Manager
                          </MenuItem>
                          <MenuItem value="Team Lead">Team Lead</MenuItem>
                          <MenuItem value="GM Coach">GM Coach</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </Box>
                    )}

                    {missingInfo.includes('phone_number') && (
                      <Box my={2}>
                        <TextField
                          name="phone_number"
                          label="Phone number"
                          type="tel"
                          inputProps={{
                            maxLength: 14,
                          }}
                          disabled={submitting}
                        />
                      </Box>
                    )}

                    {missingInfo.includes('email') && (
                      <>
                        <Box my={2}>
                          <TextField
                            name="email"
                            label="Email"
                            type="email"
                            disabled={submitting}
                          />
                        </Box>
                        {values.email && values.email.length > 0 && (
                          <>
                            <Box my={2}>
                              <TextField
                                name="password"
                                label="Password"
                                type="password"
                                inputProps={{
                                  maxLength: 127,
                                }}
                                autoComplete="off"
                                disabled={submitting}
                                required
                              />
                            </Box>
                            <Box my={2}>
                              <TextField
                                name="confirm_password"
                                label="Confirm password"
                                placeholder="Re-enter your password"
                                type="password"
                                inputProps={{
                                  maxLength: 127,
                                }}
                                autoComplete="off"
                                disabled={submitting}
                                required
                              />
                            </Box>
                          </>
                        )}
                      </>
                    )}

                    <Box my={2}>
                      <>
                        {missingInfo.includes('sms_consent') &&
                        (!missingInfo.includes('phone_number') ||
                          (values.phone_number &&
                            values.phone_number.length > 0)) ? (
                          <Box mb={1} textAlign="center">
                            <Typography variant="caption" color="textSecondary">
                              By clicking submit you agree to receive automated
                              text messages.
                            </Typography>
                            <Field
                              name="sms_consent"
                              initialValue="true"
                              type="hidden"
                              component="input"
                            />
                          </Box>
                        ) : null}
                      </>

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!formIsValid || submitting}
                        startIcon={
                          submitting ? (
                            <CircularProgress
                              size={16}
                              color="inherit"
                              disableShrink
                            />
                          ) : null
                        }
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Box>

                    {!missingInfo.includes('sms_onboarding_store_location') && (
                      <Box my={2}>
                        <Link
                          href="#" // preventDefault keeps us from using href
                          onClick={onSkip}
                          underline="always"
                          color="textSecondary"
                        >
                          Skip
                        </Link>
                      </Box>
                    )}
                  </form>
                );
              }}
            />

            {!!error && (
              <Box mt={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </PublicLayout>
  );
};
