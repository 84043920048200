import React, { useEffect } from 'react';
import moment from 'moment';
import { find } from 'lodash';
import { useFormState, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FieldArray } from 'react-final-form-arrays';
import { Select, Switches, DatePicker } from 'mui-rff';

import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  StoresAsyncSearchSelector,
  EstimatedJobDuration,
  CustomStartAndEndServiceWindow,
  StartAndEndDateDeltaHandler,
} from 'components/form-components';
import { useCurrentBrand } from 'helpers/hooks';
import { DialogTitle } from 'dialogs/components';
import analytics from 'core/analytics';
import { SelfServe } from 'types/api';
import { FEATURE_FLAGS } from 'core/config';
import ldWrapper from 'core/ldWrapper';
import { ScheduleJobsFormValues } from 'types';

import { FormOrReviewProps } from './FormOrReview';
import { MainWrapper, BottomButtonsWrapper } from '../styles';

interface Props extends FormOrReviewProps {}

export default function ScheduleJobsForm({ onClose }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const currentBrand = useCurrentBrand();
  const { values, invalid } = useFormState<ScheduleJobsFormValues>();
  const form = useForm();

  // We assert that self_serve isn't null here since that's taken care of in the
  // parent component and we'll never get this far without it being non-null
  const ss = currentBrand.self_serve as SelfServe;
  const { configs: jobTemplates, time_windows: timeWindows } = ss;

  useEffect(() => analytics.log('view', 'schedule_jyves__form'), []);

  const jobTemplateValues = jobTemplates.map(j => ({
    label: j.display_name,
    value: j.job_template_id,
  }));
  const selectedJobTemplate = find(jobTemplates, {
    job_template_id: Number(values.jobTemplateId),
  });
  const suggestedDuration = selectedJobTemplate?.suggested_estimated_duration
    ? moment
        .duration(selectedJobTemplate.suggested_estimated_duration)
        .asMinutes()
    : null;

  const brandDefinedServiceWindows =
    !!timeWindows &&
    Object.values(timeWindows).map(timeWindow => ({
      label: timeWindow.display_name,
      value: `${timeWindow.start}-${timeWindow.end}`,
    }));

  // TODO: This is here to restrict access to a feature for some of our Enterprise clients
  // as pricing and SLA details aren't getting handled in a sufficient way.  We should be
  // removing this ASAP.
  const showRecurringSelector = !ldWrapper.hasAccess(
    FEATURE_FLAGS.createRepeatableJobDisabled
  );

  return (
    <>
      <DialogTitle title="Add Jyves" onClose={onClose} />
      <MainWrapper>
        <Grid container spacing={3}>
          {/* STORES */}
          <Grid item xs={12}>
            <FieldArray name="stores" component={StoresAsyncSearchSelector} />
          </Grid>
          {/* JOB TYPE */}
          <Grid item xs={12}>
            <Select
              name="jobTemplateId"
              label="Job type"
              data={jobTemplateValues}
              variant="outlined"
              fullWidth
            />

            <OnChange name="jobTemplateId">
              {value => {
                const newTemplate = find(jobTemplates, {
                  job_template_id: Number(value),
                });

                if (newTemplate?.suggested_estimated_duration) {
                  form.change(
                    'estimatedJobDuration',
                    moment
                      .duration(newTemplate.suggested_estimated_duration)
                      .asMinutes()
                  );
                }
              }}
            </OnChange>
          </Grid>

          {/* ESTIMATED JOB DURATION */}
          <Grid item xs={12}>
            <EstimatedJobDuration
              name="estimatedJobDuration"
              suggestedDuration={suggestedDuration}
              fieldProps={{ initialValue: suggestedDuration || 120 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* SERVICE WINDOW */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Service window
            </Typography>

            <Switches
              name="customServiceWindow"
              data={{
                label: (
                  <Typography color="textSecondary" variant="caption">
                    Customize service window
                  </Typography>
                ),
                value: true,
              }}
              color="primary"
              formControlLabelProps={
                isMobile
                  ? {
                      labelPlacement: 'start',
                      style: {
                        justifyContent: 'space-between',
                        marginLeft: 0,
                      },
                    }
                  : undefined
              }
              formControlProps={{
                fullWidth: isMobile,
              }}
              hidden={!brandDefinedServiceWindows}
              fieldProps={{
                initialValue: !brandDefinedServiceWindows,
                type: !brandDefinedServiceWindows ? 'hidden' : 'checkbox',
              }}
            />
          </Grid>

          {values.customServiceWindow || !brandDefinedServiceWindows ? (
            <CustomStartAndEndServiceWindow />
          ) : (
            <>
              {/* SERVICE DATE */}
              <Grid item xs={12} sm={6}>
                <DatePicker
                  name="startDateAndTime"
                  label="Service date"
                  format="ddd, MMM D"
                  disablePast
                  maxDate={moment()
                    .add(30, 'days')
                    .toDate()}
                  inputVariant="outlined"
                  fullWidth
                />
              </Grid>

              {/* PREDEFINED SERVICE WINDOWS */}
              <Grid item xs={12} sm={6}>
                <Select
                  name="startAndEndTimes"
                  label="Local service window"
                  data={brandDefinedServiceWindows}
                  fieldProps={{
                    initialValue: brandDefinedServiceWindows[0].value,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          )}

          <StartAndEndDateDeltaHandler />

          {/* RECURRING SELETOR */}
          {showRecurringSelector && (
            <Grid item xs={12}>
              <Select
                name="recurring"
                fieldProps={{ initialValue: 'no' }}
                variant="outlined"
              >
                <MenuItem value="no">Does not repeat</MenuItem>
                <MenuItem value="weekly">
                  Repeat weekly on {values.startDateAndTime?.format('dddd')}s
                </MenuItem>
              </Select>
            </Grid>
          )}
        </Grid>
      </MainWrapper>

      <BottomButtonsWrapper>
        <Button
          onClick={onClose}
          variant="contained"
          id="schedule-jobs-cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          id="schedule-jobs-next-button"
          disabled={invalid}
        >
          Review
        </Button>
      </BottomButtonsWrapper>
    </>
  );
}
