/* INDEX FILE
  This file, from a module perspective, behaves as the duck file from the original proposal.
  It exports as default the reducer function of the duck.
  It exports as named export the selectors and the operations.
  Optionally it exports the actions and types if they are needed in other ducks.
*/

import reducers from './reducers';
import * as userActions from './actions';
import * as userOperations from './operations';
import * as userTypes from './types';

export { userActions, userOperations, userTypes };

export default reducers;
