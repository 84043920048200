/* ACTIONS */
import { INIT_CANCEL_JOB_DIALOG, CLOSE_CANCEL_JOB_DIALOG } from './constants';

export const cancelJobById = (jobId: number) => ({
  type: INIT_CANCEL_JOB_DIALOG,
  payload: jobId,
});

export const closeJobCancelDialog = () => ({
  type: CLOSE_CANCEL_JOB_DIALOG,
});
