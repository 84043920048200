import HTTP from 'core/http';
import moment from 'moment';
import axios, { CancelTokenSource, AxiosResponse } from 'axios';

import { API } from 'core/config';
import { ScheduleJobsFormValues, ReScheduleJobFormValues } from 'types';
import {
  PaginatedApiResponse,
  Job,
  JobsRequestParams,
  JobsRequestResponse,
  JobRescheduleResponse,
  JobTemplate,
} from 'types/api';

type ScheduleJobsRequestParams = ScheduleJobsFormValues & {
  brandPublicId: string;
};

type ReScheduleJobRequestParams = ReScheduleJobFormValues & {
  jobId: number;
};

type JobsRequestOptions = {
  dryRun?: boolean;
};

export type GetJobTemplatesParams = {
  brand__public_id: string;
  with_jobs_starting_on__gte: string;
  with_jobs_starting_on__lte: string;
};

export type JobTemplatesRequestCreator = (
  params: GetJobTemplatesParams
) => Promise<AxiosResponse<PaginatedApiResponse<Array<JobTemplate>>>>;

export const JOBS_SERVICE_PAGE_SIZE = 40;

export default class JobsService {
  /**
   * @name scheduleJobs
   * @author Magnus <magnus@jyve.com>
   */
  static async scheduleJobs(
    values: ScheduleJobsRequestParams,
    options: JobsRequestOptions = {}
  ): Promise<JobsRequestResponse> {
    const { dryRun = false } = options;
    const store_ids = values.stores?.map(s => s.value);
    let job_start_after = null;
    let job_finish_before = null;

    if (values.customServiceWindow) {
      job_start_after = values.startDateAndTime.format(
        'YYYY-MM-DD[T]HH[:00:00]'
      );
      job_finish_before = values.endDateAndTime.format(
        'YYYY-MM-DD[T]HH[:00:00]'
      );
    } else {
      const date = values.startDateAndTime.clone();
      const [startTime, endTime] = values.startAndEndTimes.split('-');
      job_start_after = date.format(`YYYY-MM-DD[T]${startTime}`);

      // Does this predefined window go over midnight?
      if (startTime > endTime) {
        job_finish_before = date
          .clone()
          .add(1, 'day')
          .format(`YYYY-MM-DD[T]${endTime}`);
      } else {
        job_finish_before = date.format(`YYYY-MM-DD[T]${endTime}`);
      }
    }

    const queryParams: JobsRequestParams = {
      store_ids: store_ids || [],
      job_template_id: values.jobTemplateId,
      estimated_duration: moment
        .duration(values.estimatedJobDuration, 'minutes')
        .toISOString(),
      job_start_after,
      job_finish_before,
      recurring: values.recurring === 'weekly',
      brand_public_id: values.brandPublicId,
      dry_run: dryRun,
    };

    const { data } = await HTTP.post<JobsRequestResponse>(
      API.scheduleJobs,
      queryParams
    );

    return data;
  }

  /**
   * @name reScheduleJob
   * @author Magnus <magnus@jyve.com>
   */
  static async reScheduleJob(
    values: ReScheduleJobRequestParams,
    options: JobsRequestOptions = {}
  ): Promise<JobRescheduleResponse> {
    const { dryRun = false } = options;
    const queryParams: Record<string, any> = {
      job_id: values.jobId,
      job_start_after: values.startDateAndTime.format(
        'YYYY-MM-DD[T]HH[:00:00]'
      ),
      job_finish_before: values.endDateAndTime.format(
        'YYYY-MM-DD[T]HH[:00:00]'
      ),
      dry_run: dryRun,
    };
    // only include estimated_duration if we have the value.  This currently messes with call to just change service window
    if (values.estimatedJobDuration) {
      queryParams.estimated_duration = moment
        .duration(values.estimatedJobDuration, 'minutes')
        .toISOString();
    }

    if (values.updateSchedule === 'true') {
      queryParams.update_future_recurring_also_aswell_as_also_sometimes = true;
    }

    const { data } = await HTTP.post<JobRescheduleResponse>(
      API.rescheduleJob,
      queryParams
    );

    return data;
  }

  /**
   * @name cancelJobById
   * @author Magnus <magnus@jyve.com>
   */
  static async cancelJobById(id: number) {
    const url = `jobs/${id}/cancel/`;
    const result = (
      await HTTP.post<Job>(url, {
        include_inactive: true,
      })
    ).data;
    return result;
  }

  /**
   * @name getJobTemplatesRequestCreator
   * @author Bruce
   */
  static getJobTemplatesRequestCreator(): JobTemplatesRequestCreator {
    let call: CancelTokenSource | null = null;
    const url = `${API.jobTemplates}/`;

    return (params: GetJobTemplatesParams) => {
      if (call) {
        call.cancel('Only one request allowed at a time.');
      }

      call = axios.CancelToken.source();

      const newParams = {
        ...params,
        page_size: 1000, // giving extra room on page size, but generally shouldn't be an issue since we're always filtering by a brand
      };

      return HTTP.get(url, { params: newParams, cancelToken: call.token });
    };
  }
}
