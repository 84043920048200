export default function simpleMemoize(fn: Function) {
  let lastArg: string;
  let lastResult: string;
  return (arg: string) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
}
