import { Brand, Config } from 'types/api';
import moment from 'moment';

/**
 * @name isCustomDurationSelection
 * @author Bruce <bruce.macfarlane@jyve.com>
 * @description Checks a chosen duration against what we get as an estimate
 * in the templates are of the brand config.
 * Currently used in analytics.
 * If for some reason there's an error we send back null instead of a boolean
 * to make it easier to track.
 */
export const isCustomDurationSelection = (
  brand: Brand,
  jobTemplateId: number | null,
  estimatedJobDuration: number
): boolean | null => {
  try {
    const configs = brand.self_serve?.configs || [];
    const template = configs
      .filter((t: Config) => t.job_template_id === jobTemplateId)
      .pop();
    if (template && template.suggested_estimated_duration !== null) {
      const origEstimation = moment
        .duration(template.suggested_estimated_duration)
        .asMinutes();
      const isCustomDuration = estimatedJobDuration !== origEstimation;
      return isCustomDuration;
    }
    return null;
  } catch (err) {
    return null;
  }
};
