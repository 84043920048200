/**
 * @question Why do we split up timezone aware date helpers and non-timezone aware?
 * @answer
 * Airbnb's react-dates component!
 *
 * Their typings are based on moment and not moment-timezone, so in order
 * for us to create helpers that work with react-dates, we need to explicitly
 * import the moment class from moment and not from moment-timezone.
 *
 * @todo
 * We desperately need to move away from this library. It hasn't been
 * updated since mid-2019, it is dependent on moment.js which is officially
 * dead and both of them are bloated dinosaurs.
 */

import React from 'react';
import moment from 'moment-timezone';
import Box from '@material-ui/core/Box';

/**
 * @name getFormattedDateRange
 * @description
 * A helper method for keeping consitency in how we display a date range,
 * skipping the year, month or day if they are unnecessary.
 * @returns string | JSX.Element
 */

export function getFormattedDateRange(
  startDateString: string,
  endDateString: string,
  storeTimezone: string,
  separateTimeAndDate?: false
): string; // When 'separateTimeAndDate' is false, expect a string
export function getFormattedDateRange(
  startDateString: string,
  endDateString: string,
  storeTimezone: string,
  separateTimeAndDate: true
): JSX.Element; // When 'seperateTimeAndDate is true, expect a JSX.Element
export function getFormattedDateRange(
  startDateString: string,
  endDateString: string,
  storeTimezone: string,
  separateTimeAndDate = false
) {
  const startDate = moment.tz(startDateString, storeTimezone);
  const endDate = moment.tz(endDateString, storeTimezone);

  if (separateTimeAndDate) {
    return (
      <>
        <Box>
          {startDate.format('hh:mma')} - {endDate.format('hh:mma')} (
          {startDate.zoneAbbr()})
        </Box>
        <Box>
          {startDate.format('MMM Do')}
          {!startDate.isSame(endDate, 'day') && (
            <> - {endDate.format('MMM Do')}</>
          )}
        </Box>
      </>
    );
  }

  if (startDate.isSame(endDate, 'day')) {
    return `${startDate.format('MMM D, ha')} - ${endDate.format(
      'ha'
    )} (${startDate.zoneAbbr()})`;
  }

  return `${startDate.format('MMM D, ha')} - ${endDate.format(
    'MMM D, ha'
  )} (${startDate.zoneAbbr()})`;
}
