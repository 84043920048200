/**
 * @name EstimatedJobDuration
 * @author Magnus <magnus@jyve.com>
 * @description
 * A select field for picking a predefined set of minutes for estimated duration
 */

import React from 'react';
import { Select, SelectProps, ShowErrorProps } from 'mui-rff';

import MenuItem from '@material-ui/core/MenuItem';

import { convertMinsToHrsMins } from 'helpers/strings';

// Create an array spanning from 30 to 900 at an increment of 30
const minutes = Array.from({ length: 30 }, (_, i) => (i + 1) * 30);

function showError({
  meta: { submitError, dirtySinceLastSubmit, error },
}: ShowErrorProps) {
  return !!((submitError && !dirtySinceLastSubmit) || error);
}

interface Props extends SelectProps {
  suggestedDuration: number | null;
}
export const EstimatedJobDuration: React.FC<Props> = ({
  suggestedDuration,
  ...props
}: Props) => {
  if (suggestedDuration && !minutes.includes(suggestedDuration)) {
    minutes.push(suggestedDuration);
  }

  // If initialValue isn't in current selections, add it
  const initialValue = props.fieldProps?.initialValue;
  if (initialValue && !minutes.includes(initialValue)) {
    minutes.push(initialValue);
  }

  const selections = minutes
    .sort((a, b) => a - b)
    .map(minute => (
      <MenuItem key={`estimated-job-duration-${minute}`} value={minute}>
        {convertMinsToHrsMins(minute)}{' '}
        {minute === suggestedDuration && '(default)'}
      </MenuItem>
    ));

  return (
    <Select
      label="Estimated job duration"
      variant="outlined"
      {...props}
      showError={showError}
    >
      {selections}
    </Select>
  );
};
