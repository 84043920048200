/**
 * @name EmptyState
 * @author Magnus <magnus@jyve.com>
 * @description
 * A simple style-based component that should be used for every instance
 * of when we need an empty state in a container. You can either provide
 * it with a description of the empty state/error or an error instance.
 * If it gets an error instance it tries to figure out what happened,
 * falling back on displaying the error.message or a "Something went wrong"
 * if all else fails.
 */

import React from 'react';
import styled, { CSSObject } from 'styled-components/macro';
import { isString } from 'lodash';
import clsx from 'clsx';
import { FallbackProps } from 'react-error-boundary';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { isAxiosError } from 'core/http';
import theme from 'core/theme';
import { ReactComponent as NoResultsIcon } from 'assets/empty-states/no-results.svg';
import { ReactComponent as NoMetricsIcon } from 'assets/empty-states/no-metrics.svg';
import { ReactComponent as NotCompletedIcon } from 'assets/empty-states/job-not-completed.svg';
import { ReactComponent as FiveHundredIcon } from 'assets/empty-states/5xx-error.svg';
import { ReactComponent as MaintenanceIcon } from 'assets/empty-states/503-error.svg';

const paper = (props: any) => (
  <Box mb={2}>
    <Paper {...props} elevation={4} />
  </Box>
);

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing(2, 2, 6)};

  p {
    max-width: 500px;
  }

  .state-icon {
    width: 200px;
    height: auto;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      max-width: 300px;
      max-height: 30vh;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &.horizontal {
    flex-direction: row;
    justify-content: center;

    .state-icon {
      width: 260px;
      margin-right: ${theme.spacing(2)}px;
    }

    .content {
      align-items: flex-start;

      ${theme.breakpoints.down('sm')} {
        text-align: center;
      }
    }
  }

  .cta-wrapper {
    margin-top: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('sm')} {
      justify-self: flex-start;
    }
  }
`;

interface Props extends Partial<FallbackProps> {
  type: 'noresults' | 'nometrics' | 'notcompleted' | '500' | '503';
  title: string;
  description?: string | JSX.Element;
  layout?: 'vertical' | 'horizontal';
  withoutPaper?: boolean;
  style?: CSSObject;
  cta?: React.ReactNode;
}

export default function EmptyState({
  type,
  title,
  description,
  error,
  layout = 'vertical',
  withoutPaper = false,
  style = {},
  cta,
}: Props) {
  let Icon = NoResultsIcon;
  switch (type) {
    case '500':
      Icon = FiveHundredIcon;
      break;
    case '503':
      Icon = MaintenanceIcon;
      break;
    case 'nometrics':
      Icon = NoMetricsIcon;
      break;
    case 'notcompleted':
      Icon = NotCompletedIcon;
      break;
    default:
      Icon = NoResultsIcon;
      break;
  }

  let descriptionContent;
  // If a description is given, we use that to override anything else
  if (description) {
    descriptionContent = description;
  } else if (error) {
    console.error(error);

    // If we don't have a description, check for an error object. This object
    // is sometimes provided by us (through a try ... catch) or by the fallback
    // component on an <ErroBoundary />

    // TODO: Probably worth creating a helper/utility function that handles
    // error messages that we want to display to our users. Something like
    // getHumanFriendlyError(). That could then be used every time we run
    // an XHR request or expect a possible error response.

    // By default, we pass on the error message if it's available,
    // otherwise fallback to a general message.
    descriptionContent =
      error.message ||
      'Sorry, but something seems to have gone wrong. Please try again later.';

    // Then we try and get a bit more granular with what has happened.
    // Is it an axios error?
    if (isAxiosError(error)) {
      // It's an axios error, check for time outs first
      if (error.code === 'ECONNABORTED') {
        // Connection timed out
        descriptionContent = 'Connection timed out. Please try again later.';
      } else if (error.response?.status === 500) {
        // 500 error
        descriptionContent =
          'Unable to communicate with server. Please try again later.';
      }
    }
  }

  return (
    <EmptyStateWrapper
      as={withoutPaper ? 'div' : paper}
      className={clsx({ horizontal: layout === 'horizontal' })}
      style={style}
    >
      <Icon className="state-icon" />

      <div className="content">
        <Typography
          variant="subtitle1"
          gutterBottom
          align={layout === 'horizontal' ? 'left' : 'center'}
        >
          {title}
        </Typography>

        <Container maxWidth="sm">
          {!isString(descriptionContent) ? (
            <>{descriptionContent}</>
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              align={layout === 'horizontal' ? 'left' : 'center'}
            >
              {descriptionContent}
            </Typography>
          )}
        </Container>

        {!!cta && <div className="cta-wrapper">{cta}</div>}
      </div>
    </EmptyStateWrapper>
  );
}
