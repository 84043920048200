/**
 * @name DropdownSelector
 * @author Magnus <magnus@jyve.com>
 * @description
 * A single select component, built to be fed into a Final Form <Field /> component
 *
 * @todo Move this to bit.dev
 */

import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

interface DropdownValues {
  label: string;
  value: string;
}

interface Props extends FieldRenderProps<string> {
  labelText?: string;
  helperText?: string;
  values: DropdownValues[];
}

export const DropdownSelector: React.FC<Props> = ({
  input,
  meta,
  labelText,
  helperText,
  values,
}: Props) => {
  const isErrored = !!meta.error && meta.touched;
  let adjustedHelperText;
  if (isErrored) {
    adjustedHelperText = meta.error;
  } else if (helperText) {
    adjustedHelperText = helperText;
  }

  return (
    <FormControl variant="outlined" error={isErrored} fullWidth>
      <InputLabel id={`dropdown-select-${input.name}`}>{labelText}</InputLabel>
      <Select
        labelId={`dropdown-select-${input.name}`}
        label={labelText}
        {...input}
      >
        {values.map(({ value, label }) => (
          <MenuItem
            key={`job-type-selector-item-${value.toLowerCase()}`}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
      </Select>

      {!!adjustedHelperText && (
        <FormHelperText>{adjustedHelperText}</FormHelperText>
      )}
    </FormControl>
  );
};
